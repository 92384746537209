import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    // Card,
    // CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Tooltip,
    // Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import logo from 'assets/images/avanto-logo.png';
import poetAvatar from 'assets/images/fake-avatar.png';

// assets
import { IconLogout } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { isPoetProject } from 'utils';
import lightLightSelector from 'assets/images/icons/light-light-theme-icon.svg';
import lightDarkSelector from 'assets/images/icons/light-dark-theme-icon.svg';
import darkLightSelector from 'assets/images/icons/dark-light-theme-icon.svg';
import darkDarkSelector from 'assets/images/icons/dark-dark-theme-icon.svg';
import { useThemeModeContext } from 'hooks/useThemeMode';
import { ExpandMore } from '@mui/icons-material';
import { capitalize } from 'utils/stringHelpers';
import TenantSelectionDialog from './TenantSelectionDialog';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { themeMode, setThemeMode } = useThemeModeContext();

    const themeIcons = {
        llight: lightLightSelector,
        ldark: lightDarkSelector,
        dlight: darkLightSelector,
        ddark: darkDarkSelector
    };
    const tenantName = localStorage.getItem('tenantPath')?.replaceAll('/', '') || 'OrderBahn';

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    const [selectedIndex] = useState(-1);
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    const [openTenantSelection, setOpenTenantSelection] = useState(false);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        try {
            logout();
        } catch (err) {
            console.error(err);
        }
    };

    // const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
    //     setSelectedIndex(index);
    //     handleClose(event);

    //     if (route && route !== '') {
    //         navigate(route);
    //     }
    // };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            {isPoetProject() ? (
                <Avatar
                    src={poetAvatar}
                    ref={anchorRef}
                    onClick={handleToggle}
                    sx={{
                        ...theme.typography.mediumAvatar,
                        margin: '8px 14px !important',
                        cursor: 'pointer'
                    }}
                    color="inherit"
                />
            ) : (
                <Chip
                    sx={{
                        height: '48px',
                        alignItems: 'center',
                        borderRadius: '27px',
                        transition: 'all .2s ease-in-out',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary[300],
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary[300],
                        '&[aria-controls="menu-list-grow"], &:hover': {
                            borderColor: theme.palette.primary.main,
                            background: `${theme.palette.primary.main}!important`,
                            color: theme.palette.primary.light,
                            '& svg': {
                                stroke: theme.palette.primary.light
                            }
                        },
                        '& .MuiChip-label': {
                            lineHeight: 0
                        }
                    }}
                    icon={
                        <Avatar
                            src={logo}
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="inherit"
                        />
                    }
                    label={
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {capitalize(tenantName)}
                            <ExpandMore
                                sx={(themeSX) => ({
                                    color: '#858585'
                                })}
                            />
                        </Box>
                    }
                    variant="outlined"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="primary"
                />
            )}

            <Popper
                onResize={null}
                onResizeCapture={null}
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ border: `1px solid #D9D9D9`, borderRadius: '8px !important' }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper sx={{ borderRadius: '8px !important' }}>
                                {open && (
                                    <MainCard
                                        elevation={16}
                                        content={false}
                                        boxShadow
                                        shadow={theme.shadows[16]}
                                        sx={{
                                            border: `1px solid #D9D9D9`,
                                            borderRadius: '8px !important',
                                            backgroundColor: theme.palette.primary[300],
                                            width: '332px !important',
                                            paddingLeft: '0'
                                        }}
                                    >
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Grid container mb={2}>
                                                <Grid item xs={8}>
                                                    <Stack>
                                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                                            <Typography variant="h4">Good Morning,</Typography>
                                                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                                {user?.name}
                                                            </Typography>
                                                        </Stack>
                                                        <Typography variant="subtitle2">Project Admin</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button
                                                        fullWidth
                                                        size="small"
                                                        sx={{
                                                            background: '#FFF !important',
                                                            borderRadius: '8px',
                                                            color: theme.palette.secondary.main
                                                        }}
                                                        onClick={() => setOpenTenantSelection(true)}
                                                    >
                                                        Switch
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Box>
                                        {/* @ts-ignore */}
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                {/* <Divider />
                                                <Card
                                                    sx={{
                                                        bgcolor:
                                                            theme.palette.mode === 'dark'
                                                                ? theme.palette.dark[800]
                                                                : theme.palette.primary.light,
                                                        my: 2
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Grid container spacing={3} direction="column">
                                                            <Grid item>
                                                                <Grid item container alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            color="primary"
                                                                            checked={sdm}
                                                                            onChange={(e) => setSdm(e.target.checked)}
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid item container alignItems="center" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            checked={notification}
                                                                            onChange={(e) => setNotification(e.target.checked)}
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                                <Divider /> */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        paddingY: '10px'
                                                    }}
                                                >
                                                    <Tooltip title="Use Light Mode">
                                                        <Box
                                                            onClick={() => setThemeMode('light')}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                marginRight: '5px',
                                                                alignItems: 'center',
                                                                borderRadius: '8px',
                                                                '& :hover': { cursor: 'pointer' }
                                                            }}
                                                        >
                                                            <img
                                                                data-testid="light-mode-selector"
                                                                src={themeMode === 'light' ? themeIcons.llight : themeIcons.dlight}
                                                                alt="Use Light Mode"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                    <Tooltip title="Use Dark Mode">
                                                        <Box
                                                            onClick={() => setThemeMode('dark')}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                borderRadius: '8px',
                                                                alignItems: 'center',
                                                                '& :hover': { cursor: 'pointer' }
                                                            }}
                                                        >
                                                            <img
                                                                data-testid="dark-mode-selector"
                                                                src={themeMode === 'light' ? themeIcons.ldark : themeIcons.ddark}
                                                                alt="Use Dark Mode"
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                                <Divider />
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    {/* <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={
                                                            undefined
                                                            // (event: React.MouseEvent<HTMLDivElement>) =>
                                                            // handleListItemClick(event, 0, '/user/account-profile/profile1')
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                    </ListItemButton> */}
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={<Typography sx={{ '& :hover': { color: '#FFF' } }}>Logout</Typography>}
                                                        />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
            <TenantSelectionDialog open={openTenantSelection} setOpen={setOpenTenantSelection} />
        </>
    );
};

export default ProfileSection;
