import { createSlice } from '@reduxjs/toolkit';

// types
import { DiscoverSendMessageDialogProps } from 'types/discover-send-message-dialog';

export const templateMessage = `Greetings, \n\nI hope this message finds you well. I'm reaching out to explore potential collaboration opportunities between our businesses. As we share a common interest in the industry, I believe there's potential for mutually beneficial partnerships. I'd be delighted to discuss how we can create value together. Looking forward to the possibility of connecting and exploring synergies. \n\nAttached you will find a portfolio of our products and services. \n\nLooking forward to your reply. \n\nBest regards,  \nJack Peters \nMarketing Director \nAdvanced Furniture \njack@advancedfurniture.com \n305 898 8878`;

const initialState: DiscoverSendMessageDialogProps = {
    open: false,
    message: templateMessage,
    recipientId: null,
    setPending: (value: boolean) => {},
    readOnly: false
};

// ======================|| SLICE - DISCOVER SEND MESSAGE DIALOG ||====================== //

const discoverSendMessageDialog = createSlice({
    name: 'discoverSendMessageDialog',
    initialState,
    reducers: {
        openDialog(state, action) {
            const { open, message, recipientId, readOnly } = action.payload as DiscoverSendMessageDialogProps;
            state.open = open || initialState.open;
            state.message = message || initialState.message;
            state.recipientId = recipientId || null;
            state.readOnly = readOnly || false;
            state.setPending = action.payload.setIsPending;
        },

        closeDialog(state) {
            state.message = templateMessage;
            state.open = false;
        },
        handleCancel(state) {
            state.setPending(false);
        },
        handleSubmit(state) {
            state.setPending(true);
        }
    }
});

export default discoverSendMessageDialog.reducer;

export const { openDialog, closeDialog, handleCancel, handleSubmit } = discoverSendMessageDialog.actions;
