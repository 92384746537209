import { Dialog, Stack, Alert, AlertTitle } from '@mui/material';

export default function AlertMessage({
    severity,
    title,
    children,
    open,
    onClose
}: {
    severity: any;
    title: string;
    children: string;
    open: boolean;
    onClose: () => void;
}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={severity}>
                    <AlertTitle>{title}</AlertTitle>
                    {children}
                </Alert>
            </Stack>
        </Dialog>
    );
}
