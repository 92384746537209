import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, DialogContent, DialogTitle, Grid, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation } from '@apollo/client';
import { MUTATION_LOGIN } from 'graphql/mutations/auth';
import { QUERY_GET_USER_DETAILS } from 'graphql/mutations/login';
import { LoadingButton } from '@mui/lab';
import useAuth from 'hooks/useAuth';
import { ITenant, TenantFromTenantSelection } from 'types/queries';
import { RootDialog } from 'views/pages/authentication/mfa-components/RootDialog';
import { GetUserDetails } from 'views/pages/authentication/SelectTenant/types';
import { SearchField } from 'views/pages/authentication/SelectTenant/components';
import { Search } from '@mui/icons-material';

interface TenantSelectionDialogProps {
    setOpen: (val: boolean) => void;
    open: boolean;
}

const TenantSelectionDialog = ({ setOpen, open }: TenantSelectionDialogProps) => {
    const theme = useTheme();
    const { loginWithBackendData } = useAuth();
    const [tenantSelected, setTenantSelected] = useState<TenantFromTenantSelection | null>(null);
    const [searchText, setSearchText] = useState('');
    const [getUserDetails, { data: tenantsData }] = useMutation<GetUserDetails>(QUERY_GET_USER_DETAILS);
    const [Login, { loading: loadingLogin }] = useMutation(MUTATION_LOGIN, {
        onCompleted: (data) => {
            localStorage.setItem('backend_jwt', data.SaasUserLogin);
            loginWithBackendData();
            setOpen(false);
            setTenantSelected(null);
            window.location.replace(`${localStorage.getItem('tenantPath')}/dashboard`);
        }
    });

    const filteredTenants =
        tenantsData?.getUserDetails
            .filter(({ tenant }) => {
                const tenantId = localStorage.getItem('tenant_id');
                return Number(tenantId) !== Number(tenant.id);
            })
            .filter(
                ({ tenant }) =>
                    tenant.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    tenant.url.toLowerCase().includes(searchText.toLowerCase())
            ) ?? [];

    const handleLogin = async (forcedTenantSelected?: Pick<ITenant, 'id' | 'name' | 'url' | 'description'>) => {
        if (!tenantSelected && !forcedTenantSelected) return;
        const tenant = tenantSelected ?? forcedTenantSelected;
        localStorage.setItem('tenant_id', tenant?.id.toString() as string);
        localStorage.setItem('tenantPath', `/${tenant?.url}`);
        try {
            await Login();
        } catch (err: any) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getUserDetails();
    }, [getUserDetails]);
    const handleClose = () => {
        setOpen(false);
    };

    const getBackgroundColor = (tenant: Pick<ITenant, 'id' | 'name' | 'url' | 'description'>, index: number) => {
        if (Number(tenantSelected?.id) === Number(tenant.id)) {
            return theme.palette.secondary.main;
        }
        return index % 2 === 0 ? '#FFFFFF' : '#F5F6F7';
    };

    return (
        <RootDialog
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="compatible-mfa-applications-modal"
            open={open}
            PaperProps={{ sx: { borderRadius: '16px' } }}
        >
            <DialogTitle sx={{ m: 0, p: 2, paddingBottom: '0 !important' }} id="compatible-mfa-applications-modal">
                <Box mb={2}>
                    <Typography gutterBottom variant="h3" sx={{ fontSize: 24, fontWeight: 600 }}>
                        Select Tenant
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <SearchField
                    InputProps={{
                        disableUnderline: true,
                        sx: { borderRadius: '32px', paddingLeft: 2, height: '40px', mb: '32px' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search htmlColor="#D9D9D9" />
                            </InputAdornment>
                        )
                    }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ mb: '12px' }}
                    InputLabelProps={{ sx: { paddingLeft: 2 } }}
                    placeholder="Find in current page"
                    fullWidth
                    type="search"
                    variant="filled"
                    size="small"
                />
                <Box
                    sx={{
                        height: '370px',
                        overflowY: 'auto',
                        borderTop: `1px solid ${theme.palette.secondary.light}`,
                        borderBottom: `1px solid ${theme.palette.secondary.light}`,
                        marginBottom: '20px'
                    }}
                >
                    {[...filteredTenants]
                        .sort((a, b) => {
                            const nameA = a.tenant.name.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.tenant.name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        })
                        .map(({ tenant }, index) => (
                            <Box
                                key={tenant.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    margin: '0',
                                    cursor: 'pointer',
                                    padding: '0.5rem 1rem',
                                    backgroundColor: getBackgroundColor(tenant, index)
                                }}
                                onClick={() => setTenantSelected(tenant)}
                            >
                                <Box>
                                    <Avatar
                                        sx={{
                                            p: '15px',
                                            bgcolor: 'white',
                                            color: 'black',
                                            border: 'solid 2px',
                                            borderColor: theme.palette.secondary.main,
                                            width: '27px',
                                            height: '27px'
                                        }}
                                    >
                                        {tenant.name[0]}
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Typography sx={{ color: Number(tenantSelected?.id) === Number(tenant.id) ? '#FFFFFF' : '#616161' }}>
                                        {tenant.name} - {tenant.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    {filteredTenants.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                margin: '0',
                                cursor: 'pointer',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            <Typography>No tenants found</Typography>
                        </Box>
                    )}
                </Box>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs="auto">
                        <Button
                            onClick={handleClose}
                            disabled={loadingLogin}
                            variant="outlined"
                            color="secondary"
                            sx={{ width: '94px', height: '40px' }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <LoadingButton
                            loading={loadingLogin}
                            onClick={() => handleLogin()}
                            disabled={!tenantSelected}
                            variant="contained"
                            color="secondary"
                            sx={{ width: '94px', height: '40px' }}
                        >
                            Login
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </RootDialog>
    );
};

export default TenantSelectionDialog;
