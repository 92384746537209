// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { SocketContext, socket } from 'contexts/socket';
// auth provider
import { ConfirmationModalContextProvider } from 'components/providers/ConfirmationModalProvider';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { ThemeModeContextProvider } from 'components/providers/ThemeModeProvider';
import DiscoverSendMessageDialog from 'ui-component/DiscoverSendMessageDialog';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <ThemeModeContextProvider>
                        <AuthProvider>
                            <ConfirmationModalContextProvider>
                                <SocketContext.Provider value={socket}>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                        <DiscoverSendMessageDialog />
                                    </>
                                </SocketContext.Provider>
                            </ConfirmationModalContextProvider>
                        </AuthProvider>
                    </ThemeModeContextProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
