export const NoFilesIcon = () => (
    <svg width="140" height="88" viewBox="0 0 140 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M70 87.3468C108.66 87.3468 140 80.5031 140 72.0611C140 63.619 108.66 56.7754 70 56.7754C31.3401 56.7754 0 63.619 0 72.0611C0 80.5031 31.3401 87.3468 70 87.3468Z"
            fill="#D9D9D9"
        />
        <path
            d="M120.312 30.0636L98.1181 4.94701C97.0528 3.23501 95.4975 2.19995 93.8591 2.19995H46.1409C44.5025 2.19995 42.9472 3.23501 41.8819 4.94482L19.6875 30.0658V50.2407H120.312V30.0636Z"
            stroke="#D9D9D9"
        />
        <path
            d="M91.0284 36.988C91.0284 33.4832 93.2028 30.5898 95.9 30.5876H120.312V70.1928C120.312 74.8288 117.425 78.6284 113.859 78.6284H26.1406C22.575 78.6284 19.6875 74.8266 19.6875 70.1928V30.5876H44.1C46.7972 30.5876 48.9716 33.4766 48.9716 36.9814V37.0295C48.9716 40.5343 51.17 43.3643 53.865 43.3643H86.135C88.83 43.3643 91.0284 40.5081 91.0284 37.0033V36.988Z"
            fill="#F5F6F7"
            stroke="#D9D9D9"
        />
    </svg>
);
