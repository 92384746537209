import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ChangeEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { List } from './components';

export type GridSingleSelectProps = {
    options: GridSingleSelectOption[];
    value: number;
    onChange: (newId: number) => void;
    leftButtonLabel?: ReactNode;
    width?: string;
    sx?: SxProps;
};

export type GridSingleSelectOption = {
    id: number;
    label: string;
};

export const GridSingleSelect = ({ options, value, onChange, leftButtonLabel, width, sx }: GridSingleSelectProps) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = useState('');

    const filteredOptions = options.filter((el) => el.label.toLowerCase().includes(searchText.toLowerCase()));
    const open = Boolean(anchorEl);

    const selectedOption = value ? options.find((el) => el.id === value) || { label: '', id: undefined } : null;

    const handleOpenOptions = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            if (searchInputRef.current) searchInputRef.current.focus();
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = e.target.value;
        setSearchText(newVal);
    };

    const handleSelectOption = (selectedId: number) => () => {
        onChange(selectedId);
        handleClose();
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: width || '200px',
                height: '40px',
                ...sx
            }}
        >
            {selectedOption ? (
                <Typography
                    fontSize="14px"
                    sx={{
                        ml: '10px',
                        maxHeight: '100%',
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {selectedOption.label}
                </Typography>
            ) : (
                leftButtonLabel
            )}
            <IconButton disableRipple onClick={handleOpenOptions} sx={{ ml: 'auto' }}>
                {open ? <ExpandLess htmlColor="#54595E" /> : <ExpandMore htmlColor="#54595E" />}
            </IconButton>
            <List
                open={open}
                anchorEl={anchorEl}
                searchInputRef={searchInputRef}
                containerRef={containerRef}
                searchText={searchText}
                options={filteredOptions}
                onClose={handleClose}
                onChangeSearch={handleChangeSearch}
                onSelectOption={handleSelectOption}
                onClearSearch={() => setSearchText('')}
            />
        </Box>
    );
};
