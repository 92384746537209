import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { CancelOutlined, Delete, Download } from '@mui/icons-material';
import { AttachmentRow } from '../types';
import { FileIcon } from './FileIcon';

export type NonPdfDialogProps = {
    open: boolean;
    fileRow: AttachmentRow | null;
    handleClose: () => void;
    onDownload: (fileRow: AttachmentRow | null) => void;
    onDelete?: () => void;
};

export const NonPdfDialog = ({ open, fileRow, handleClose, onDownload, onDelete }: NonPdfDialogProps) => (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle id="alert-dialog-title">
            <Grid container justifyContent="space-between">
                <Grid item xs={10}>
                    <Typography variant="h4" color="black" fontSize="24px" fontWeight={500}>
                        {fileRow?.name}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton color="secondary" onClick={handleClose}>
                        <CancelOutlined />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#F5F6F7', py: '36px !important', px: '60px', position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '12px',
                    right: '8px',
                    background: 'transparent',
                    zIndex: '999',
                    height: 'auto',
                    width: '40px'
                }}
            >
                <Stack spacing={2}>
                    <Tooltip title="Download">
                        <IconButton
                            sx={(theme) => ({
                                color: theme.palette.primary[400],
                                backgroundColor: '#FFF',
                                fontWeight: 600,
                                '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                            })}
                            color="secondary"
                            onClick={() => onDownload(fileRow)}
                        >
                            <Download />
                        </IconButton>
                    </Tooltip>
                    {onDelete && (
                        <Tooltip title="Delete">
                            <IconButton
                                sx={(theme) => ({
                                    color: theme.palette.primary[400],
                                    backgroundColor: '#FFF',
                                    fontWeight: 600,
                                    '&:hover': { color: theme.palette.primary[400], backgroundColor: '#FFF' }
                                })}
                                color="secondary"
                                onClick={() => onDelete()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Box>
            {fileRow && <FileIcon fileType={fileRow.fileType} fileSize={fileRow.fileSize} />}
        </DialogContent>
    </Dialog>
);
