import React, { MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem, MenuList, Paper } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import MenuIcon from '@mui/icons-material/Menu';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { useDispatch } from 'store';
import { setLoading } from 'store/slices/submission';

export type GridToolbarActionsProps = {
    onDelete: () => Promise<void>;
    onClickBulkEdit: () => void;
    trigger: string;
    show?: boolean;
    showDelete?: boolean;
};

export const GridToolbarActions = ({ onDelete, onClickBulkEdit, show, trigger, showDelete }: GridToolbarActionsProps) => {
    const apiRef = useGridApiContext();
    const modal = useConfirmationModalContext();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const recordCount = apiRef.current.getSelectedRows().size;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const onClickDelete = async () => {
        setAnchorEl(null);
        await modal.showConfirmation({
            title: 'Delete',
            icon: <WarningIcon color="warning" sx={{ marginLeft: '1rem' }} fontSize="large" />,
            content: `You are about to delete ${
                recordCount === 1 ? `${recordCount} record` : `${recordCount} records`
            }. Do you wish to continue?`,
            loadingText: 'Deleting',
            forwardButtonText: 'Delete',
            onForward: handleDelete,
            onAction: null,
            showCheckbox: false,
            actionType: 'delete',
            sectionTitle: 'record_grid',
            recordType: trigger || ''
        });
    };

    const handleDelete = async () => {
        dispatch(setLoading(true));
        await onDelete();
        dispatch(setLoading(false));
    };

    return (
        <>
            {!!recordCount && show && (
                <>
                    <Button
                        sx={({ palette }) => ({
                            color: palette.primary[400],
                            backgroundColor: palette.primary[300],
                            borderRadius: '30px',
                            padding: '8px 16px'
                        })}
                        startIcon={<MenuIcon />}
                        size="medium"
                        color="primary"
                        onClick={handleClick}
                    >
                        Actions
                    </Button>
                    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                        <Paper sx={{ width: 100, maxWidth: '100%' }}>
                            <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        onClickBulkEdit();
                                    }}
                                >
                                    Bulk Edit
                                </MenuItem>
                            </MenuList>
                            {showDelete && (
                                <MenuList>
                                    <MenuItem onClick={onClickDelete}>Delete</MenuItem>
                                </MenuList>
                            )}
                        </Paper>
                    </Menu>
                </>
            )}
        </>
    );
};
