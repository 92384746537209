import { Box, Typography } from '@mui/material';

export type FileIconProps = {
    fileType: string;
    fileSize: string;
};

export const FileIcon = ({ fileType, fileSize }: FileIconProps) => (
    <Box sx={{ position: 'relative' }}>
        <svg
            width="447"
            height="607"
            style={{ height: 'calc(100vh - 300px)', width: '100%' }}
            viewBox="0 0 447 607"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_10354_7883)">
                <path
                    d="M0 40.623C0 18.5317 17.9086 0.623047 40 0.623047H186C330.146 0.623047 447 117.477 447 261.623V566.623C447 588.714 429.091 606.623 407 606.623H40C17.9086 606.623 0 588.714 0 566.623V40.623Z"
                    fill="white"
                />
                <path d="M223 10.623H437V224.623H303C258.817 224.623 223 188.806 223 144.623V10.623Z" stroke="#37326E" strokeWidth="20" />
            </g>
            <path
                d="M10 40.623C10 24.0545 23.4315 10.623 40 10.623H186C324.623 10.623 437 123 437 261.623V566.623C437 583.192 423.569 596.623 407 596.623H40C23.4315 596.623 10 583.192 10 566.623V40.623Z"
                stroke="#37326E"
                strokeWidth="20"
            />
            <defs>
                <clipPath id="clip0_10354_7883">
                    <path
                        d="M0 40.623C0 18.5317 17.9086 0.623047 40 0.623047H186C330.146 0.623047 447 117.477 447 261.623V566.623C447 588.714 429.091 606.623 407 606.623H40C17.9086 606.623 0 588.714 0 566.623V40.623Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
        <Box sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translateX(-50%)' }}>
            <Typography variant="h1" align="center" color="secondary" textTransform="uppercase" fontSize="4rem" fontWeight={900}>
                {fileType.split('/')[1]}
            </Typography>
            <Typography variant="h1" align="center" color="secondary" fontSize="1.5rem" fontWeight={400}>
                {fileSize} KB
            </Typography>
        </Box>
    </Box>
);
