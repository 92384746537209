import React, { useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    FormControlProps,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { Clear } from '@mui/icons-material';
import { IUser } from 'views/backoffice/users/types';

export interface UsersDropdownProps {
    onChange: (value: string | string[]) => void;
    initialValue?: string | string[];
    variant?: FormControlProps['variant'];
    size?: FormControlProps['size'];
    label?: string;
    native?: boolean;
    multiple?: boolean;
    fieldName?: string;
    fromFilterPanel?: boolean;
}

export const UsersDropdown = ({
    onChange,
    initialValue,
    native,
    label,
    variant = 'outlined',
    size = 'medium',
    multiple = false,
    fromFilterPanel = false
}: UsersDropdownProps) => {
    const [userSelected, setUserSelected] = useState('');
    const [userMultipleSelected, setUserMultipleSelected] = useState<string[]>([]);

    const { data: usersData, loading } = useQuery<{ getSaasUsers: IUser[] }>(QUERY_GET_USERS, {
        onCompleted() {
            const userList = usersData?.getSaasUsers;
            if (userList) {
                if (multiple) {
                    const values = userList.map((el) => String(el.id)).filter((el) => initialValue?.includes(el));
                    setUserMultipleSelected(values || []);
                } else if (fromFilterPanel || Number(initialValue)) {
                    setUserSelected(initialValue as string);
                } else {
                    const value = userList.find(({ name }) => (initialValue as string) === name)?.id;
                    setUserSelected(String(value) || '');
                }
            }
        }
    });

    const usersList =
        usersData?.getSaasUsers
            .filter((el: any) => Boolean(el.email))
            .sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }) || [];
    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setUserMultipleSelected(newValue as string[]);
        setUserSelected(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = multiple ? ['0'] : '0';
        if (multiple) setUserMultipleSelected([newValue] as string[]);
        else setUserSelected(newValue as string);
        onChange(newValue);
    };

    return (
        <Box sx={{ minWidth: 149 }}>
            <FormControl variant={variant} size={size} fullWidth disabled={loading}>
                {label && (
                    <InputLabel id="user-select" shrink>
                        {label}
                    </InputLabel>
                )}
                {native ? (
                    <NativeSelect value={userSelected} onChange={handleValueChange} sx={{ pt: '3px !important' }}>
                        <option value=""> &nbsp;</option>
                        {usersList?.map((el) => (
                            <option key={el.id} value={el.id}>
                                {el.name}
                            </option>
                        ))}
                    </NativeSelect>
                ) : (
                    <Select
                        value={multiple ? userMultipleSelected : userSelected}
                        onChange={handleValueChange}
                        variant={variant}
                        size={size}
                        multiple={multiple}
                        sx={{ pt: '3px !important' }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: !multiple && userSelected && userSelected !== '0' ? '' : 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                        {...(!multiple && userSelected && userSelected !== '0' && { IconComponent: () => null })}
                    >
                        {usersList
                            ?.filter((user) => !user.enabled && +user.id === +userSelected)
                            .map((el) => (
                                <MenuItem key={el.id} value={el.id} disabled={!el.enabled}>
                                    (Disabled)&nbsp;{el.name}
                                </MenuItem>
                            ))}
                        {usersList
                            ?.filter((user) => user.enabled)
                            .map((el) => (
                                <MenuItem key={el.id} value={el.id} disabled={!el.enabled}>
                                    {el.name}
                                </MenuItem>
                            ))}
                    </Select>
                )}
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
