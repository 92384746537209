import { Box, TextField } from '@mui/material';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { getDateByHour } from 'ui-component/records/utils';

export interface TimeEditCellProps {
    onChange: (value: string) => void;
    field: string;
    value?: string;
}

export const TimeEditCell = ({ onChange, field, value, hideLabel }: TimeEditCellProps & { hideLabel?: boolean }) => {
    const [dateValue, setDateValue] = useState(value ? getDateByHour(value as string) : null);

    useEffect(() => {
        value && setDateValue(new Date(value));
    }, [value]);

    const handleChange = async (newValue: string | null) => {
        if (newValue === undefined) return;
        setDateValue(newValue ? new Date(newValue) : null);
        onChange(newValue ?? '');
    };

    return (
        <Box sx={{ minWidth: 149 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker label={field} value={dateValue} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
        </Box>
    );
};
