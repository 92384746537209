import { Avatar, Box, Chip, Typography } from '@mui/material';
import { AuditLog } from 'ui-component/RecordView/types';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Circle } from '@mui/icons-material';

export type LogItemProps = {
    item: AuditLog;
    onOpen: () => void;
};

export const LogItem = ({ onOpen, item }: LogItemProps) => (
    <Box
        onClick={onOpen}
        sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            gap: '16px',
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary[400],
                background: theme.palette.primary[300]
            }
        })}
    >
        <Circle color="secondary" fontSize="small" />
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start'
            }}
        >
            <Box>
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.secondary.main,
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '19.9px',
                        letterSpacing: '0.4px'
                    })}
                >
                    {format(new Date(item.datetime), 'MMM d, y - h:mmaaaa')}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box>
                    <Avatar
                        sx={(theme) => ({
                            bgcolor: '#FFF',
                            border: `1px solid ${theme.palette.secondary.main}`,
                            width: 24,
                            height: 24,
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center'
                        })}
                    >
                        {item.user.firstName.split('')[0]}
                    </Avatar>
                </Box>
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: '#54595E',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '21.98px',
                            letterSpacing: '0.1px'
                        })}
                    >
                        {`${item.user.firstName} ${item.user.lastName}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box>
            <Chip
                label={
                    <Typography
                        textTransform="capitalize"
                        sx={{
                            color: '#858585',
                            fontWeight: '500',
                            fontSize: '12px',
                            lineHeight: '19.9px',
                            letterSpacing: '0.4px'
                        }}
                    >
                        <FormattedMessage id={item.action} />
                    </Typography>
                }
                sx={{
                    border: `1px solid #858585`,
                    backgroundColor: 'transparent'
                }}
            />
        </Box>
    </Box>
);
