import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, HighlightOff } from '@mui/icons-material';
import { AuditLog } from '../types';
import { mapRecordLogToAuditLogTable } from '../utils';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

export type DetailsDialogProps = {
    item: AuditLog | null;
    onClose: () => void;
};
export const historyReportColumns = [
    {
        field: 'fieldName',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Field Name
            </Typography>
        ),
        width: 180,
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'before',
        width: 336,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Before
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.before) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.before) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            color: theme.palette.primary[400],
                            cursor: 'default !important',
                            transition: 'none !important',
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return <span>{row.before}</span>;
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'after',
        width: 336,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                After
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => {
            if (String(row.after) === 'true')
                return (
                    <Checkbox
                        disabled
                        checked
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            if (String(row.after) === 'false')
                return (
                    <Checkbox
                        disabled
                        checked={false}
                        onChange={() => {}}
                        sx={(theme) => ({
                            padding: 0,
                            cursor: 'default !important',
                            transition: 'none !important',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                );
            return <span>{row.after}</span>;
        },
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'activity',
        width: 100,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Activity
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Chip
                    label={
                        <Typography
                            textTransform="capitalize"
                            sx={{
                                color: '#858585',
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '19.9px',
                                letterSpacing: '0.4px'
                            }}
                        >
                            <FormattedMessage id={row.activity} />
                        </Typography>
                    }
                    sx={{
                        border: `1px solid #858585`,
                        backgroundColor: 'transparent',
                        minWidth: '80px'
                    }}
                />
            </Box>
        ),
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'user',
        width: 160,
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                User
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box>
                    <Avatar
                        sx={(theme) => ({
                            bgcolor: '#FFF',
                            border: `1px solid ${theme.palette.secondary.main}`,
                            width: 24,
                            height: 24,
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center'
                        })}
                    >
                        {row.user.split('')[0]}
                    </Avatar>
                </Box>
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: '#54595E',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '21.98px',
                            letterSpacing: '0.1px'
                        })}
                    >
                        {`${row.user}`}
                    </Typography>
                </Box>
            </Box>
        ),
        hideSortIcons: true,
        sortable: false
    },
    {
        field: 'dateUpdated',
        renderHeader: () => (
            <Typography color="text.dark" variant="h4">
                Date Updated
            </Typography>
        ),
        renderCell: ({ row }: GridRenderCellParams) => (
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Box>
                    <Typography
                        sx={(theme) => ({
                            color: '#54595E',
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '21.98px',
                            letterSpacing: '0.1px'
                        })}
                    >
                        {format(new Date(row.dateUpdated), 'MMM d, y - h:mmaaaa')}
                    </Typography>
                </Box>
            </Box>
        ),
        width: 220,
        hideSortIcons: true,
        sortable: false
    }
];

export const DetailsDialog = ({ onClose, item }: DetailsDialogProps) => (
    <Dialog open={!!item} onClose={onClose} maxWidth="xl">
        {!!item && (
            <>
                <DialogTitle
                    id="form-dialog-title"
                    sx={{
                        fontSize: '24px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingBottom: 0
                    }}
                >
                    <Box>
                        Data <FormattedMessage id={`title-${item.action}`} />
                    </Box>
                    <IconButton onClick={onClose}>
                        <HighlightOff sx={(theme) => ({ color: theme.palette.secondary.main })} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: '300px' }}>
                        <DataGridPro
                            columns={historyReportColumns}
                            rows={mapRecordLogToAuditLogTable(item)}
                            getRowHeight={() => 'auto'}
                            onCellKeyDown={(_params, event) => {
                                event.defaultMuiPrevented = true;
                            }}
                            sx={{
                                '& .MuiDataGrid-virtualScroller': { marginTop: '0 !important', height: '300px !important' },
                                '& .MuiDataGrid-row': {
                                    alignItems: 'center',
                                    border: 'solid 1px #dddddd',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    py: 1
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': { outlineColor: 'transparent !important', outlineWidth: 0 },
                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outlineColor: 'transparent !important', outlineWidth: 0 }
                            }}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            hideFooter
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button variant="contained" color="secondary" onClick={onClose} size="small">
                        Done
                    </Button>
                </DialogActions>
            </>
        )}
    </Dialog>
);

export default DetailsDialog;
