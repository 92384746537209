import { useQuery } from '@apollo/client';
import { QUERY_GET_MY_ITEM_ACTIONS } from 'graphql/queries/layout';
import { QUERY_GET_MENU_ITEMS } from 'graphql/queries/menuItems';
import { IAppModule } from 'layout/MainLayout/Sidebar/MenuList/types';
import { getAllMenuItems, getFilteredMenu, getMyMenuItems } from 'layout/MainLayout/Sidebar/utils';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'store';
import { saveActionData, saveMenuItemList } from 'store/slices/menu';
import { IGetItemActions } from 'types/layout';

const useMyMenuItems = () => {
    const dispatch = useDispatch();
    const { loading: menuLoading, data: menuData } = useQuery<{ SaasMenu: IAppModule[] }>(QUERY_GET_MENU_ITEMS);
    const { loading: actionsLoading, data: actionsData } = useQuery<IGetItemActions>(QUERY_GET_MY_ITEM_ACTIONS);
    const myMenuItemsIds = React.useMemo(
        () => getMyMenuItems(actionsData?.SaasMyRoleMenuItemActions ?? []),
        [actionsData?.SaasMyRoleMenuItemActions]
    );

    const filteredList: IAppModule[] = useMemo(
        () => getFilteredMenu(menuData?.SaasMenu ?? [], myMenuItemsIds),
        [menuData?.SaasMenu, myMenuItemsIds]
    );
    const menuItemList = useMemo(() => getAllMenuItems(filteredList) || [], [filteredList]);

    const getMenuItemByRecordTypeId = (recordTypeId: number) => {
        const selectedMenuItem = menuItemList.find((el) => Number(el.recordType?.id) === recordTypeId);
        return selectedMenuItem?.fullPath;
    };

    useEffect(() => {
        if (menuItemList) dispatch(saveMenuItemList(menuItemList));
    }, [menuItemList, dispatch]);

    useEffect(() => {
        if (actionsData) dispatch(saveActionData(actionsData.SaasMyRoleMenuItemActions || []));
    }, [actionsData, dispatch]);

    if (menuLoading || actionsLoading) return { loading: true };
    return { loading: false, filteredList, menuItemList, getMenuItemByRecordTypeId };
};

export default useMyMenuItems;
