import { Button, Grid } from '@mui/material';

export type FooterHistoryPanelProps = {
    onShowFullHistory: () => void;
};

export const FooterHistoryPanel = ({ onShowFullHistory }: FooterHistoryPanelProps) => (
    <Grid container justifyContent="flex-start" sx={{ borderTop: 'solid 1px #D9D9D9', py: '12px', px: '12px' }}>
        <Button variant="outlined" color="secondary" sx={{ borderRadius: '8px' }} onClick={onShowFullHistory}>
            Show full history
        </Button>
    </Grid>
);

export default FooterHistoryPanel;
