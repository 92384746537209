import MainCard from 'ui-component/cards/MainCard';
import { RecordGrid } from 'ui-component/records';
import { IMenuItem } from 'layout/MainLayout/Sidebar/MenuList/types';
import { useMemo } from 'react';

interface RenderRecordTypeProps {
    menuItem: IMenuItem;
}
const RenderRecordType = ({ menuItem }: RenderRecordTypeProps) => {
    const gridName = menuItem.recordType?.name ?? '';
    const recordTypeId = useMemo(() => Number(menuItem?.recordType?.id), [menuItem]);

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <MainCard
                title={menuItem?.name}
                style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', paddingLeft: '6px' }}
                content={false}
                border={false}
            >
                <RecordGrid gridName={gridName} recordTypeId={recordTypeId} />
            </MainCard>
        </div>
    );
};

export default RenderRecordType;
