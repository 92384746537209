import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { Box, Checkbox, Chip, FormGroup, Grid, Link, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'store';
import { IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { getTimeValidator } from 'ui-component/records/utils';
import { getDateWithoutTZ, getRelativeDateTimeUTCFormat } from 'ui-component/records/utils/dateHelpers';
import { ListValue } from 'views/backoffice/CustomLists/types';

export type RenderViewFieldProps = {
    field: FieldToView;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
    headerData: IRecordFieldsList | null;
};

export type FieldToView = {
    label: string;
    dataType: IRecordField['dataType'];
    value: any;
    isBaseField?: boolean;
    options?: any[];
};

export const RenderViewField = ({ field, handleOpenViewObjectPanel, headerData }: RenderViewFieldProps) => {
    const { drawerOpen } = useSelector((state) => state.menu);
    const theme = useTheme();
    const { label, dataType, value, options = [] } = field;
    const isListOfObjects = headerData?.[label as keyof IRecordFieldsList].listType?.objectDefinition?.id;

    let formattedFieldValue = <></>;
    if (dataType.toLowerCase() === 'date' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Tooltip
                    placement="bottom-start"
                    title={field.isBaseField ? getRelativeDateTimeUTCFormat(value) : new Date(value).toDateString()}
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px'
                        }}
                        color="#818181"
                    >
                        {format(getDateWithoutTZ(value as string), 'MM/dd/yyyy')}
                    </Typography>
                </Tooltip>
            </Grid>
        );
    if (dataType.toLowerCase() === 'time' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Typography
                    style={{
                        maxWidth: '90%',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 10,
                        padding: 10,
                        fontWeight: 400,
                        fontSize: '13px'
                    }}
                    color="#818181"
                >
                    {getTimeValidator(value as string)}
                </Typography>
            </Grid>
        );

    if (dataType.toLowerCase() === 'datetime' && value.length)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Tooltip
                    placement="bottom-start"
                    // @ts-ignore
                    title={value.length ? new Date(value).toGMTString().replace('GMT', 'UTC') : ''}
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px'
                        }}
                        color="#818181"
                    >
                        {value.length ? format(new Date(value as string), 'MM/dd/yyyy hh:mm a') : ''}
                    </Typography>
                </Tooltip>
            </Grid>
        );

    if (
        (dataType.toLowerCase() === 'object' || (dataType.toLowerCase() === 'dropdown' && isListOfObjects)) &&
        headerData?.[label as keyof IRecordFieldsList]
    )
        formattedFieldValue = (
            <>
                <Grid item xs={12} style={{ padding: '5px 0px' }}>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                        <Grid item xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                            {!!value && (
                                <Typography
                                    style={{
                                        paddingLeft: 10,
                                        padding: 10,
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: theme.palette.secondary.main
                                    }}
                                    color="#818181"
                                    onClick={() => handleOpenViewObjectPanel(headerData[label as keyof IRecordFieldsList], label, false)}
                                >
                                    {value}
                                </Typography>
                            )}
                            {!value && (
                                <Typography
                                    style={{
                                        paddingLeft: 10,
                                        padding: 10,
                                        fontWeight: 400,
                                        fontSize: '13px',
                                        textDecoration: 'underline',
                                        color: theme.palette.secondary.main
                                    }}
                                    color="#818181"
                                >
                                    No Data
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );

    if (['string', 'number', 'decimal', 'currency', 'textarea', 'dropdown'].includes(dataType.toLowerCase()) && !isListOfObjects)
        formattedFieldValue = (
            <Grid item xs={6}>
                <Typography
                    style={{
                        maxWidth: '90%',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: 10,
                        padding: 10,
                        fontWeight: 400,
                        fontSize: '13px'
                    }}
                    color="#818181"
                >
                    {value}
                </Typography>
            </Grid>
        );

    if (dataType.toLowerCase() === 'checkbox')
        formattedFieldValue = (
            <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid xs={6}>
                    <Checkbox
                        defaultChecked
                        checked={value === 'true'}
                        disabled
                        sx={{
                            padding: '10px',
                            paddingLeft: '0px',
                            color: theme.palette.primary[400],
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        }}
                        icon={<CheckBoxOutlineBlankOutlined />}
                        checkedIcon={<CheckBoxOutlined />}
                    />
                </Grid>
            </Grid>
        );

    if (dataType.toLowerCase() === 'url')
        formattedFieldValue = (
            <Grid item xs={6}>
                <Link
                    style={{ textDecoration: 'none' }}
                    href={value.includes('http') || value.includes('https') ? value : `https://${value}`}
                    target="_blank"
                >
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: 10,
                            padding: 10,
                            fontWeight: 400,
                            fontSize: '13px',
                            textDecoration: 'underline'
                        }}
                        color="#818181"
                    >
                        {value}
                    </Typography>
                </Link>
            </Grid>
        );

    if (dataType.toLowerCase() === 'multiselect-checkbox')
        formattedFieldValue = (
            <Grid item xs={12}>
                {options.map((item: ListValue) => (
                    <FormGroup style={{ padding: 10 }} key={item.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} sx={{ paddingLeft: '30px' }}>
                                <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                    <FormattedMessage
                                        id={item.userValue?.name || item.value}
                                        defaultMessage={item.userValue?.name || item.value}
                                    />
                                </Typography>
                            </Grid>
                            <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Checkbox
                                    checked={Array.isArray(value) && value.includes(Number(item.id))}
                                    disabled
                                    sx={{
                                        padding: 0,
                                        color: theme.palette.primary[400],
                                        '&.Mui-checked': { color: theme.palette.primary.dark },
                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                    }}
                                    icon={<CheckBoxOutlineBlankOutlined />}
                                    checkedIcon={<CheckBoxOutlined />}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                ))}
            </Grid>
        );
    if (dataType.toLowerCase() === 'multiselect')
        formattedFieldValue = (
            <Grid item xs={12}>
                {Array.isArray(value) &&
                    value.map((name: string, idx: number) => {
                        const backgroundColors = [theme.palette.secondary.main, '#1845A7', '#005208'];
                        return (
                            <Chip
                                sx={{
                                    margin: '0.5rem',
                                    backgroundColor: `${backgroundColors[idx % 3]} !important`,
                                    color: `#fff`,
                                    marginLeft: '30px'
                                }}
                                label={name}
                                key={`${idx}-${name}`}
                            />
                        );
                    })}
            </Grid>
        );

    return (
        <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 10 }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <Grid item xs={6} paddingLeft="20px">
                    <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                        <FormattedMessage id={label} defaultMessage={label} />
                    </Typography>
                </Grid>
                {!value && !['multiselect-checkbox', 'checkbox', 'object'].includes(dataType) ? (
                    <Grid item xs={12}>
                        <Box style={{ paddingLeft: 10, padding: 10, fontWeight: 400, fontSize: '13px' }} color="#818181" />
                    </Grid>
                ) : (
                    formattedFieldValue
                )}
            </Grid>
        </Grid>
    );
};
