import { CircularProgress, Fab, Grid, Tooltip } from '@mui/material';
import { Close, Edit, Save } from '@mui/icons-material';
import { PanelTitle } from '../utils';

export type FooterProps = {
    panelState: PanelTitle;
    onEdit: () => void;
    onSave: () => Promise<void>;
    onCancel: () => void;
    isCreate?: boolean;
    disabledButtons?: boolean;
};

export const Footer = ({ panelState, onEdit, onSave, onCancel, isCreate, disabledButtons }: FooterProps) => (
    <Grid container justifyContent="flex-end" sx={{ borderTop: 'solid 1px #D9D9D9', py: '12px' }}>
        {panelState === PanelTitle.View && (
            <Tooltip title="Edit">
                <Fab color="secondary" onClick={onEdit} sx={{ width: 40, height: 40 }} disabled={disabledButtons}>
                    <Edit />
                </Fab>
            </Tooltip>
        )}

        {panelState === PanelTitle.Edit && (
            <>
                {!isCreate && (
                    <Tooltip title="Discard Changes">
                        <Fab onClick={onCancel} sx={{ width: 40, height: 40, mx: '8px', boxShadow: 'none', '& svg': { color: '#858585' } }}>
                            <Close />
                        </Fab>
                    </Tooltip>
                )}
                <Tooltip title="Save Changes">
                    <Fab
                        color="secondary"
                        onClick={onSave}
                        sx={{ width: 40, height: 40, mx: '8px', boxShadow: 'none' }}
                        disabled={disabledButtons}
                    >
                        {disabledButtons ? <CircularProgress size={20} color="inherit" /> : <Save />}
                    </Fab>
                </Tooltip>
            </>
        )}
    </Grid>
);

export default Footer;
