import { gql } from '@apollo/client';

/**
 * @deprecated Now use QUERY_GET_RECORD_HEADERS instead
 */
export const QUERY_GET_PROPERTIES_PANEL = gql`
    query FindRecordHeader($data: RecordHeaderFindInput!, $pagination: PaginationInputType) {
        findRecordHeader(data: $data, pagination: $pagination) {
            id
            tenant {
                name
                id
            }
            recordType {
                id
                name
            }
            recordFile {
                id
            }
            statusId {
                id
                name
            }
            createdBy {
                id
                createdAt
                name
            }
            receivedBy {
                name
                id
            }
            approvedBy {
                name
                id
            }
            approvedAt
            assignedTo {
                id
                name
            }
            recordNumber
            enabled
            additionalFields {
                id
                tag
                value
            }
        }
    }
`;
