import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_ROLE_ACCESS, QUERY_GET_RECORD_FIELDS } from 'graphql/queries/bills';
import useMyRole from 'hooks/useMyRole';
import { FindRoleAccessVariables, IFindRoleAccess, IGetRecordFields } from 'ui-component/records/types';

export type useRecordDataProps = {
    recordTypeId: number;
};

export const useRecordData = ({ recordTypeId }: useRecordDataProps) => {
    const roleId = useMyRole();

    const [getData, { data: headerData, loading: loadingHeaderData }] = useLazyQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS, {
        variables: { recordType: Number(recordTypeId) },
        fetchPolicy: 'no-cache'
    });
    const [getFieldAccess, { data: fieldAccessData, loading: loadingFieldAccess }] = useLazyQuery<IFindRoleAccess, FindRoleAccessVariables>(
        QUERY_FIND_ROLE_ACCESS,
        {
            variables: { data: { recordTypes: [Number(recordTypeId)], roles: [roleId as number] } },
            fetchPolicy: 'no-cache'
        }
    );

    const loading = loadingHeaderData || loadingFieldAccess;

    const fieldAccess = useMemo(() => fieldAccessData?.findRoleAccessToRecordFields || [], [fieldAccessData]);
    const headers = useMemo(() => headerData?.getSaasRecordFieldsByRecordTypes, [headerData]);

    useEffect(() => {
        if (recordTypeId) {
            getData();
            getFieldAccess();
        }
    }, [recordTypeId, getData, getFieldAccess]);

    return { loading, headers, fieldAccess };
};
