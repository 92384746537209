import React, { useEffect, useState } from 'react';
import { ClassNameMap, Grid, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChevronLeft, ChevronRight, FirstPage, KeyboardArrowDown, KeyboardArrowUp, LastPage } from '@mui/icons-material';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';

export interface PaginationFooterProps {
    classes: ClassNameMap;
    rowCountState: number;
    currentPage: number;
    handlePageChange: any;
    currentPageSize: number;
    handlePageSizeChange: (pageSize: number) => Promise<void>;
}
export const PaginationFooter = ({
    classes,
    rowCountState,
    currentPage,
    handlePageChange,
    currentPageSize,
    handlePageSizeChange
}: PaginationFooterProps) => {
    const storeDispatch = useDispatch();
    const [inputValue, setInputValue] = useState((currentPage + 1) as number | null);
    const [applyAnchorEl, setApplyAnchorEl] = useState<HTMLDivElement | null>(null);
    const rowsPerPageOptions = [25, 50, 100, 200, 500, 1000, 2000, 3000];

    useEffect(() => {
        setInputValue(currentPage + 1);
    }, [currentPage]);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            if (inputValue !== null && inputValue !== currentPage + 1) {
                if (inputValue <= Math.ceil(rowCountState / currentPageSize)) {
                    handlePageChange(inputValue - 1);
                } else {
                    handlePageChange(Math.ceil(rowCountState / currentPageSize) - 1);
                }
            }
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [currentPage, currentPageSize, handlePageChange, inputValue, rowCountState]);

    const handleClickButtons = (pageSize: number) => {
        setApplyAnchorEl(null);
        handlePageSizeChange(pageSize);
    };

    const calcPageRecords = () => {
        const start = currentPage * currentPageSize + 1;
        const end = Math.min((currentPage + 1) * currentPageSize, rowCountState);
        return `${start} - ${end} of ${rowCountState}`;
    };
    const shouldNext = currentPage + 1 !== Math.ceil(rowCountState / currentPageSize);
    const shouldPrev = currentPage > 0;

    return (
        <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
            <Grid item xs="auto" className={classes.paginationItems}>
                <Box
                    sx={{
                        mr: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        background: '#F5F6F7',
                        padding: '8px 12px',
                        borderRadius: '100px',
                        cursor: 'pointer'
                    }}
                    onClick={(event) => setApplyAnchorEl(event.currentTarget)}
                >
                    <Typography variant="body1" sx={{ color: '#858585' }} fontWeight={500} component="div">
                        Rows per page &nbsp;{currentPageSize}
                    </Typography>
                    {applyAnchorEl ? (
                        <KeyboardArrowDown className={classes.paginationArrow} />
                    ) : (
                        <KeyboardArrowUp className={classes.paginationArrow} />
                    )}
                </Box>
                <Menu
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    anchorEl={applyAnchorEl}
                    open={!!applyAnchorEl}
                    onClose={() => setApplyAnchorEl(null)}
                    style={{ padding: 0 }}
                >
                    {rowsPerPageOptions.map((val) => (
                        <MenuItem
                            key={val}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickButtons(val);
                            }}
                        >
                            {val}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
            <Grid
                item
                xs="auto"
                container
                component={Box}
                sx={{ mr: '5px', justifyContent: 'flex-end', background: '#F5F6F7', padding: '3px 12px', borderRadius: '100px' }}
            >
                <Grid item xs="auto" sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        value={inputValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.value) {
                                if (Number(event.target.value) <= Math.ceil(rowCountState / currentPageSize)) {
                                    if (Number(event.target.value) === 0) {
                                        setInputValue(1);
                                    } else {
                                        setInputValue(Number(event.target.value));
                                    }
                                } else {
                                    storeDispatch(
                                        openSnackbar({
                                            open: true,
                                            message: `There are only ${Math.ceil(rowCountState / currentPageSize)} pages!`,
                                            variant: 'alert',
                                            alert: { color: 'error', severity: 'error' },
                                            close: true
                                        })
                                    );
                                    setInputValue(Math.ceil(rowCountState / currentPageSize));
                                }
                            } else {
                                setInputValue(null);
                            }
                        }}
                        type="number"
                        size="small"
                        sx={{
                            maxWidth: '24px',
                            height: '32px',
                            borderRadius: '8px',
                            '& input': {
                                textAlign: 'center',
                                padding: '5px 7px !important'
                            },
                            'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none',
                                margin: 0
                            }
                        }}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: Math.ceil(rowCountState / currentPageSize)
                            }
                        }}
                    />
                    <Tooltip title={`${inputValue} of ${Math.ceil(rowCountState / currentPageSize)} ( ${calcPageRecords()} )`}>
                        <Typography
                            sx={{
                                marginLeft: '5px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                            }}
                            variant="body1"
                            className={classes.gridToolbarCustomFont}
                            component="div"
                        >
                            of {Math.ceil(rowCountState / currentPageSize)} ( {calcPageRecords()} )
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs="auto" className={classes.paginationItems}>
                    <IconButton
                        disabled={!shouldPrev}
                        className={classes.paginationControl}
                        size="small"
                        onClick={() => {
                            handlePageChange(0);
                        }}
                    >
                        <FirstPage className={classes.paginationArrow} />
                    </IconButton>
                    <IconButton
                        disabled={!shouldPrev}
                        className={classes.paginationControl}
                        size="small"
                        onClick={() => {
                            handlePageChange(currentPage - 1);
                        }}
                    >
                        <ChevronLeft className={classes.paginationArrow} />
                    </IconButton>
                    <IconButton
                        disabled={!shouldNext}
                        className={classes.paginationControl}
                        size="small"
                        onClick={() => {
                            handlePageChange(currentPage + 1);
                        }}
                    >
                        <ChevronRight className={classes.paginationArrow} />
                    </IconButton>
                    <IconButton
                        disabled={!shouldNext}
                        className={classes.paginationControl}
                        size="small"
                        onClick={() => {
                            handlePageChange(Math.ceil(rowCountState / currentPageSize) - 1);
                        }}
                    >
                        <LastPage className={classes.paginationArrow} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
