import * as CognitoSDK from '@aws-sdk/client-cognito-identity-provider';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { AWS_API } from 'config';
import { InitialLoginContextProps } from 'types/auth';

export const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const awsAdminClient = new CognitoSDK.CognitoIdentityProviderClient({
    region: AWS_API.region,
    credentials: {
        accessKeyId: AWS_API.accessKeyId,
        secretAccessKey: AWS_API.secretAccessKey
    }
});

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

export const cleanLocalStorage = () => {
    localStorage.removeItem('backend_jwt');
    localStorage.removeItem('shouldSkipSetupMFA');
    localStorage.removeItem('associateSoftwareToken');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('tenantSelected');
    localStorage.removeItem('tenantPath');
    localStorage.removeItem('tenant_id');
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) localStorage.setItem('serviceToken', serviceToken);
    else localStorage.removeItem('serviceToken');
};

export const AUTH_ROUTES = [
    '/login',
    '/register',
    '/forgot',
    '/recover',
    '/reset',
    '/user-confirmation',
    '/change',
    '/configured',
    '/setupmfa',
    '/select-tenant'
] as const;
