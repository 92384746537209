import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material';
import React from 'react';

export const SkeletonLoader = () => (
    <ListItemButton>
        <ListItemAvatar>
            <Skeleton variant="circular" height={50} width={50} />
        </ListItemAvatar>
        <ListItem sx={{ px: 0 }}>
            <ListItemText
                primary={<Skeleton width="30%" />}
                secondary={
                    <>
                        <Typography component="span" variant="body2">
                            <Skeleton width="50%" />
                        </Typography>
                        <Typography component="span" variant="body2">
                            <Skeleton />
                        </Typography>
                    </>
                }
            />
        </ListItem>
    </ListItemButton>
);

export const SkeletonLoaderList = () => (
    <List>
        {[1, 2, 3, 4].map((el) => (
            <SkeletonLoader key={el} />
        ))}
    </List>
);
