import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

export type DatePickerInputProps = {
    value: string;
    onChange: (newValue: Dayjs | null) => Promise<void>;
    textFieldProps?: TextFieldProps;
};

export const DatePickerInput = ({ value, onChange, textFieldProps }: DatePickerInputProps) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            onChange={(newValue) => onChange((newValue as unknown as Dayjs) || null)}
            value={value}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    {...params}
                    InputProps={{
                        ...textFieldProps?.InputProps,
                        ...params.InputProps
                    }}
                    variant={textFieldProps?.variant || params.variant}
                    size={textFieldProps?.size || params.size}
                />
            )}
        />
    </LocalizationProvider>
);
