export const LineItemIcon = ({ color = '#858585' }: { color?: string }) => (
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 4V6H12V4H14ZM10 4V6H4V4H10ZM10 8H4V10H10V8ZM12 8V10H14V8H12ZM18 19L15 17L12 19L9 17L6 19L3 17L0 19V0H18V19ZM16 15.26V2H2V15.26L3 14.6L6 16.6L9 14.6L12 16.6L15 14.6L16 15.26Z"
            fill={color}
        />
    </svg>
);

export default LineItemIcon;
