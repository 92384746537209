/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import { useCallback, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import io from 'socket.io-client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { IUserDataToken } from 'utils/types';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { CommentSection, NewCommentSection } from './components';
import { Comment, ListenCommentPayload, ListenInitialCommentPayload } from './types';
import { generateCommentTree } from './utils';
import { SOCKET_URL } from 'config';

export const CommentsPanel = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const recordId = queryParams.get('recordId') ?? '';
    const tenantId = localStorage.getItem('tenant_id');
    const socket = useMemo(() => io(`${SOCKET_URL}?recordHeaderId=${recordId}`), [recordId]);

    const token = localStorage.getItem('backend_jwt') ?? '';
    const user: IUserDataToken = jwtDecode(token);
    const [selectedTab, setSelectedTab] = useState('1');
    const [commentList, setCommentList] = useState<Comment[]>([]);

    const { parentComments, childComments } = generateCommentTree(commentList);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    // Emitters

    const handleSendComment = (message: string, parentComment?: string | null, commentId?: string) => {
        socket.emit(commentId ? 'updateComment' : 'createComment', {
            _id: commentId,
            userId: user.userId,
            content: message,
            recordHeaderId: +recordId,
            tenantId: +(tenantId as string),
            parentComment
        });
    };

    const handleDeleteComment = async (message: Comment) => {
        socket.emit('deleteComment', {
            commentId: message._id
        });
    };

    // Listeners

    const onInitialCommentList = useCallback((payload: ListenInitialCommentPayload) => {
        setCommentList(payload.comments);
    }, []);

    const onNewComments = useCallback(
        (payload: ListenCommentPayload) => {
            if (+payload.comment?.recordHeaderId === +recordId || +(payload as unknown as Comment)?.recordHeaderId === +recordId) {
                const newState = (prev: Comment[]) => {
                    const itExist = prev.find(
                        // this assertion is needed because the payload could be a Comment type
                        // in update events and a ListenCommentPayload in create events
                        (comment) => comment._id === (payload as unknown as Comment)._id || comment._id === payload.comment?._id
                    );
                    const newArr = itExist
                        ? prev.map((el) => {
                              if (el._id === (payload as unknown as Comment)._id) return payload as unknown as Comment;
                              if (el._id === payload.comment?._id) return payload.comment;
                              return el;
                          })
                        : [...prev, payload.comment];
                    return newArr.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                };
                setCommentList(newState);
            }
        },
        [recordId]
    );

    const onDeleteComments = useCallback(
        async (payload: Comment) => {
            setCommentList((prev) => prev.map((el) => (el._id === payload._id ? payload : el)));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Comment Deleted!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (socket) {
            socket.emit('getComments', { recordHeaderId: +recordId, tenantId: +(tenantId as string), order: 'desc' });
        }
    }, [recordId, socket, tenantId]);

    useEffect(() => {
        if (socket) {
            // subscribe to socket events
            socket.on('comment', onNewComments);
            socket.on('comments', onInitialCommentList);
            socket.on('deleted', onDeleteComments);
        }

        return () => {
            if (socket) {
                socket.off('comment', onNewComments);
                socket.off('comments', onInitialCommentList);
                socket.off('deleted', onDeleteComments);
            }
        };
    }, [onNewComments, onInitialCommentList, onDeleteComments, socket]);

    return (
        <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: '-30px' }}>
                <TabList onChange={handleChange}>
                    <Tab label="ALL" value="1" sx={{ fontSize: '20px' }} />
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, minHeight: 'calc(100% - 108px)', overflowY: 'auto' }}>
                <NewCommentSection onSendComment={handleSendComment} />

                {/* Comments */}

                <Grid container sx={{ width: '100%', mt: '8px' }}>
                    {!!parentComments.length &&
                        parentComments.map((comment) => (
                            <CommentSection
                                key={comment._id}
                                comment={comment}
                                childs={childComments[comment._id]}
                                onUpdateComment={handleSendComment}
                                onSendReply={(message) => handleSendComment(message, comment._id)}
                                onDeleteComment={handleDeleteComment}
                            />
                        ))}
                </Grid>
            </TabPanel>
        </TabContext>
    );
};
