/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { useCommentUsers } from '../../hooks';
import { Comment } from '../../types';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NewCommentSection } from '../NewCommentSection';
import { CommentView } from './components';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';

export type CommentSectionProps = {
    comment: Comment;
    onDeleteComment?: (comment: Comment) => Promise<void>;
    onSendReply?: (message: string) => void;
    onUpdateComment: (message: string, parentComment?: string | null, commentId?: string) => void;
    childs?: Comment[];
};

export const CommentSection = ({ comment, onDeleteComment, onSendReply, onUpdateComment, childs }: CommentSectionProps) => {
    const modal = useConfirmationModalContext();
    const { usersById, currentUser } = useCommentUsers();
    const [isOpenReplies, setIsOpenReplies] = useState(false);
    const [showNewComment, setShowNewComment] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleClickDelete = async () => {
        if (!onDeleteComment) return;
        await modal.showConfirmation({
            title: 'Do you want to delete this comment?',
            content: '',
            loadingText: 'Deleting',
            forwardButtonText: 'Yes',
            actionButtonText: 'No',
            onForward: () => onDeleteComment(comment),
            onAction: null,
            showCheckbox: true,
            actionType: 'delete',
            sectionTitle: 'comment',
            recordType: String(comment.recordHeaderId)
        });
    };

    const handleSendReply = (message: string) => {
        onSendReply?.(message);
        setIsOpenReplies(true);
    };

    return (
        <Grid item container key={comment._id} spacing={2} xs={12} sx={{ mb: '15px' }}>
            <Grid item xs="auto" sx={{ textAlign: 'right' }}>
                <Avatar
                    sx={(theme) => ({
                        mt: 1,
                        width: 40,
                        height: 40,
                        bgcolor: 'white',
                        color: theme.palette.secondary.main,
                        border: `solid 1px ${theme.palette.secondary.main}`,
                        display: 'inline-flex',
                        mr: '12px'
                    })}
                >
                    {usersById[comment.userId]?.name[0]}
                </Avatar>
            </Grid>
            <Grid
                item
                container
                xs={11}
                sx={{ flexGrow: '1 !important', px: '0 !important', maxWidth: '-webkit-fill-available !important' }}
            >
                <Grid item xs={12}>
                    {isEditing ? (
                        <NewCommentSection
                            onSendComment={(message) => onUpdateComment(message, comment.parentComment, comment._id)}
                            onCancel={() => setIsEditing(false)}
                            initialContent={comment.content}
                            initialFocus
                            editMode
                        />
                    ) : (
                        <CommentView
                            userId={comment.userId}
                            tenant={comment.tenant}
                            content={comment.content}
                            createdAt={comment.createdAt}
                        />
                    )}
                </Grid>
                {!isEditing && (
                    <Grid item container xs={12} alignItems="center">
                        {+currentUser.id === +comment.userId && (
                            <>
                                <Grid item xs="auto">
                                    <Typography
                                        variant="body1"
                                        color="secondary"
                                        fontSize="14px"
                                        fontWeight={500}
                                        onClick={() => setIsEditing(true)}
                                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    >
                                        Edit
                                    </Typography>
                                </Grid>
                                {!childs?.length && (
                                    <>
                                        <Grid item xs="auto">
                                            <Typography variant="caption" color="secondary" fontWeight={500} sx={{ margin: '0 10px' }}>
                                                •
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography
                                                variant="body1"
                                                color="secondary"
                                                fontSize="14px"
                                                fontWeight={500}
                                                onClick={handleClickDelete}
                                                sx={{ color: '#D32F2F', cursor: 'pointer', textDecoration: 'underline' }}
                                            >
                                                Delete
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        {!comment.parentComment && (
                            <>
                                <Grid item xs="auto">
                                    <Typography variant="caption" color="secondary" fontWeight={500} sx={{ margin: '0 10px' }}>
                                        •
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography
                                        variant="body1"
                                        color="secondary"
                                        fontSize="14px"
                                        fontWeight={500}
                                        onClick={() => setShowNewComment(true)}
                                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    >
                                        Add reply
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {!!childs?.length && (
                            <>
                                <Grid item xs="auto">
                                    <Typography variant="caption" color="secondary" fontWeight={500} sx={{ margin: '0 10px' }}>
                                        •
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        size="small"
                                        variant="contained"
                                        disableElevation
                                        onClick={() => setIsOpenReplies(!isOpenReplies)}
                                        endIcon={
                                            isOpenReplies ? (
                                                <ExpandLess htmlColor={isOpenReplies ? '#37326E' : '#858585'} />
                                            ) : (
                                                <ExpandMore htmlColor={isOpenReplies ? '#37326E' : '#858585'} />
                                            )
                                        }
                                        sx={{
                                            borderRadius: '100px',
                                            bgcolor: isOpenReplies ? '#EBEEFE' : '#F5F6F7',
                                            '&:hover': { bgcolor: isOpenReplies ? '#EBEEFE' : '#F5F6F7' }
                                        }}
                                    >
                                        <Typography variant="body1" fontSize="14px" sx={{ color: isOpenReplies ? '#37326E' : '#858585' }}>
                                            {childs.length === 1 ? '1 Comment' : `${childs.length} Comments`}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
                {((childs?.length && isOpenReplies) || showNewComment) && (
                    <Grid item container xs={12} sx={{ mt: '10px' }}>
                        {showNewComment && onSendReply && (
                            <NewCommentSection onSendComment={handleSendReply} onCancel={() => setShowNewComment(false)} initialFocus />
                        )}
                        {childs?.length &&
                            childs.map((child) => (
                                <CommentSection
                                    key={child._id}
                                    comment={child}
                                    onUpdateComment={onUpdateComment}
                                    onDeleteComment={onDeleteComment}
                                />
                            ))}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
