import { HighlightOff } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'store';
import { openDialog } from 'store/slices/discover-send-message-dialog';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { TypeTenant } from 'views/discover';

export interface INotificationCard {
    tenant: TypeTenant & { message: string };
    handleIgnoreNotification: (tenantId: number) => void;
}

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: '#F6F6F6'
        }
    }
}));

const NotificationCard = ({ tenant, handleIgnoreNotification }: INotificationCard) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showConnected, setShowConnected] = useState(false);
    return (
        <Box className={classes.mainContainer}>
            <Grid container>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        src={tenant.profilePic}
                        sx={{
                            width: 30,
                            height: 30
                        }}
                    />
                </Grid>
                <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#000',
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '90%'
                        }}
                    >
                        {tenant.name}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        gutterBottom
                        component="div"
                        sx={{
                            fontSize: '10px',
                            color: '#BFBFBF',
                            textAlign: 'right',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '90%'
                        }}
                    >
                        {dayjs().format('HH:mm a')}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton
                        color="secondary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleIgnoreNotification(tenant.id);
                        }}
                    >
                        <HighlightOff />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Typography
                        gutterBottom
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontSize: '12px',
                            textAlign: 'left',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                            width: '100%'
                        }}
                    >
                        {!showConnected && tenant.message}
                        {showConnected && 'Connected!'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {!showConnected && (
                        <>
                            <AnimateButton>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleIgnoreNotification(tenant.id);
                                    }}
                                    sx={{ marginRight: '1rem' }}
                                    disableElevation
                                    fullWidth
                                    size="small"
                                    variant="text"
                                    color="inherit"
                                >
                                    Ignore
                                </Button>
                            </AnimateButton>
                            <AnimateButton>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowConnected(true);
                                    }}
                                    disableElevation
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Accept
                                </Button>
                            </AnimateButton>
                        </>
                    )}
                    {showConnected && (
                        <>
                            <AnimateButton>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            openDialog({
                                                open: true,
                                                recipientId: tenant.id,
                                                setIsPending: (_val: boolean) => {}
                                            })
                                        );
                                    }}
                                    disableElevation
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Send Message
                                </Button>
                            </AnimateButton>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotificationCard;
