// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, Divider, IconButton, Menu, MenuItem, SvgIcon, Tooltip, Typography } from '@mui/material';
import { NotificationsNoneOutlined } from '@mui/icons-material';

// project imports
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import LocalizationSection from './LocalizationSection';
// import MegaMenuSection from './MegaMenuSection';
// import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { TypeTenant } from 'views/discover';

// assets
import { useState } from 'react';
import { openDialog as openSendMessage } from 'store/slices/discover-send-message-dialog';
import { ReactComponent as beamerIcon } from 'assets/images/icons/beamer-icon.svg';
import LogoSection from '../LogoSection';
import { useLocation } from 'react-router-dom';
import DashboardTitle from './DashboardTitle';
import NotificationCard from './NotificationCard';
import { tenantListMock } from './tenantListMock';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export const OrderBahnHeader = () => {
    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();

    const isDashboard = location.pathname.split('/').slice(2).join('/') === 'dashboard';

    const { sectionName: title } = useSelector((state) => state.section);
    const { drawerOpen } = useSelector((state) => state.menu);
    const [anchorNotificationEl, setAnchorNotificationEl] = useState<null | HTMLElement>(null);

    // --------------------------------------------------------------- For notifications frame
    const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorNotificationEl(event.currentTarget);
    const handleNotificationClose = () => setAnchorNotificationEl(null);
    const [tenantList, setTenantList] = useState<(TypeTenant & { message: string })[]>(tenantListMock);

    const handleIgnoreNotification = (tenantId: number) => {
        const tenantListFiltered = tenantList.filter((tenant) => tenant.id !== tenantId);
        setTenantList(tenantListFiltered);
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 260,
                    height: 88,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    backgroundColor: drawerOpen ? theme.palette.secondary.light : 'transparent',
                    paddingX: '2rem',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {!drawerOpen && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '20px',
                            backgroundColor: theme.palette.secondary.light,
                            position: 'absolute',
                            left: 0
                        }}
                    />
                )}
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    <LogoSection />
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, marginLeft: 'calc(260px + 2rem)' }}>
                {isDashboard ? (
                    <DashboardTitle />
                ) : (
                    <Typography variant="h3" color="secondary" fontFamily="Poppins, sans-serif">
                        {title}
                    </Typography>
                )}
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box sx={{ marginRight: '6px' }}>
                    <IconButton
                        sx={{ color: '#858585', backgroundColor: theme.palette.primary[300], marginRight: '0.5rem' }}
                        className="beamerTrigger"
                        color="secondary"
                        size="large"
                    >
                        <SvgIcon component={beamerIcon} />
                    </IconButton>
                    <Tooltip title="Notifications">
                        <IconButton onClick={handleNotificationClick} size="large" sx={{ backgroundColor: theme.palette.primary[300] }}>
                            {tenantList.length > 0 && (
                                <Badge
                                    badgeContent={tenantList.length}
                                    sx={{
                                        '&>span.MuiBadge-badge': {
                                            backgroundColor: 'rgb(255, 90, 95)',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <NotificationsNoneOutlined sx={{ color: theme.palette.primary[400] }} />
                                </Badge>
                            )}
                            {tenantList.length === 0 && <NotificationsNoneOutlined />}
                        </IconButton>
                    </Tooltip>
                </Box>
                {/* Notifications */}
                <Menu
                    anchorEl={anchorNotificationEl}
                    open={Boolean(anchorNotificationEl)}
                    onClose={handleNotificationClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            minWidth: 350
                        }
                    }}
                >
                    <Box sx={{ borderBottom: '1px solid #D9D9D9' }}>
                        <Typography sx={{ display: 'flex', justifyContent: 'center', mb: '5px' }}>
                            You have {tenantList.length} new notification{`${tenantList.length > 1 ? 's' : ''}`}
                        </Typography>
                    </Box>
                    {tenantList.map((item) => (
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                    openSendMessage({
                                        open: true,
                                        recipientId: item.id,
                                        readOnly: true,
                                        message: `${item.message} \n\nGreetings.\n${item.name}`,
                                        setIsPending: (_val: boolean) => {}
                                    })
                                );
                            }}
                        >
                            <NotificationCard tenant={item} handleIgnoreNotification={handleIgnoreNotification} />
                        </MenuItem>
                    ))}
                    {tenantList.length > 0 && (
                        <>
                            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                    paddingRight: '1rem',
                                    borderRadius: '15px'
                                }}
                            >
                                <Button
                                    onClick={() => setTenantList([])}
                                    sx={{ padding: '0.5rem', '&:hover': { backgroundColor: 'rgba(193, 2, 48, 0.04)', color: '#c10230' } }}
                                    disableElevation
                                    size="small"
                                    variant="text"
                                    color="inherit"
                                >
                                    Clear All
                                </Button>
                            </Box>
                        </>
                    )}
                </Menu>
            </Box>

            {/* <NewOrderDialog usersList={usersList} open={isOpenDialog} onClose={handleCloseDialog} onSubmit={saveChange} />

            <ManufacturerOrderDialog
                usersList={usersList}
                open={openManufacturerDialog}
                onClose={() => {
                    setOpenManufacturerDialog(false);
                }}
                onSubmit={saveChange}
            />

            <RfqDialog
                usersList={usersList}
                open={openRfqDialog}
                onClose={() => {
                    setOpenRfqDialog(false);
                }}
                onSubmit={saveChange}
            />

            <QuoteDialog
                usersList={usersList}
                open={openQuoteDialog}
                onClose={() => {
                    setOpenQuoteDialog(false);
                }}
                onSubmit={saveChange}
            /> */}

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};
