import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import { ModalContextType } from 'hooks/useConfirmationModal';
import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

export const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

export interface IDialogData {
    // title: () => JSX.Element;
    title: string;
    onForward: (() => Promise<void>) | null;
    onAction: (() => void) | null;
    content: string | ReactNode;
    forwardButtonText?: string;
    actionButtonText?: string;
    showCheckbox: boolean;
    sectionTitle: string;
    actionType: string;
    recordType: string;
    icon?: JSX.Element;
    loadingText?: string;
    hideCloseBtn?: boolean;
}
export const ConfirmationModalContextProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState<IDialogData>({
        title: '',
        content: '',
        forwardButtonText: 'Confirm',
        loadingText: 'Loading',
        onForward: null,
        onAction: null,
        showCheckbox: false,
        hideCloseBtn: false,
        actionType: '',
        sectionTitle: '',
        recordType: ''
    });

    const resolver = useRef<(value: boolean) => void>();

    useEffect(() => {
        // Validate local storage
        const tenantName = localStorage.getItem('tenantPath')?.replaceAll('/', '');
        const checkedConfiguration = localStorage.getItem(
            `checked_${tenantName}_${data.sectionTitle}${
                data.recordType ? `_${data.recordType}_${data.actionType}` : `_${data.actionType}`
            }`
        );
        if (checkedConfiguration) {
            setShowConfirmationModal(false);
            data.onForward?.();
        }
        if (!checkedConfiguration && data.title) {
            setShowConfirmationModal(true);
        }
    }, [data]);

    const handleShow = (newData: IDialogData): Promise<boolean> => {
        setChecked(false);
        setData((s) => ({ ...s, ...newData }));

        return new Promise((resolve) => {
            resolver.current = resolve;
        });
    };

    const handleOk = async () => {
        if (data.showCheckbox && checked) {
            const tenantName = localStorage.getItem('tenantPath')?.replaceAll('/', '');
            localStorage.setItem(
                `checked_${tenantName}_${data.sectionTitle}${
                    data.recordType ? `_${data.recordType}_${data.actionType}` : `_${data.actionType}`
                }`,
                'checked'
            );
        }
        await data.onForward?.();
        resolver.current && resolver.current(true);
        setShowConfirmationModal(false);
    };

    const handleAction = async () => {
        await data.onAction?.();
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false);
    };

    const handleClose = async () => {
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false);
    };

    const toogleLoading = (loading: boolean) => {
        setIsLoading(loading);
    };

    return (
        <ConfirmationModalContext.Provider
            value={{
                showConfirmation: handleShow,
                hideConfirmation: () => setShowConfirmationModal(false),
                isShown: showConfirmationModal,
                toogleLoading
            }}
        >
            {children}

            <Dialog
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                sx={{ '& .MuiPaper-root': { padding: 0 } }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle component="div" sx={{ paddingX: 1 }}>
                    <Grid container alignItems="center">
                        <>
                            {data.icon && data.icon}
                            <Typography variant="h3" sx={{ flexGrow: 1, marginLeft: '1rem', fontWeight: 500 }} fontSize="24px">
                                {data.title}
                            </Typography>
                        </>
                        {!data.hideCloseBtn && (
                            <IconButton onClick={handleClose}>
                                <HighlightOffOutlined fontSize="medium" color="secondary" />
                            </IconButton>
                        )}
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ pb: '0 !important' }}>
                    {!!data.content && typeof data.content === 'string' && (
                        <Typography variant="body1" sx={{ fontWeight: 'normal', color: 'black' }} component="div">
                            {data.content}
                        </Typography>
                    )}

                    {!!data.content && typeof data.content !== 'string' && data.content}

                    {data.showCheckbox === true && (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        sx={(theme) => ({
                                            color: theme.palette.secondary.main,
                                            '&.Mui-checked': { color: theme.palette.secondary.main },
                                            '& .MuiSvgIcon-root': { fontSize: '30px !important' }
                                        })}
                                    />
                                }
                                label="Don't show this again"
                            />
                        </FormGroup>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        disabled={isLoading}
                        variant="outlined"
                        color="secondary"
                        sx={{ p: '12px !important', lineHeight: 1, borderRadius: '8px' }}
                        onClick={handleAction}
                    >
                        {data.actionButtonText ?? 'Cancel'}
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        color="secondary"
                        sx={{ p: '12px !important', lineHeight: 1, borderRadius: '8px' }}
                        onClick={handleOk}
                        loadingIndicator={<CircularProgress size={12} color="inherit" />}
                        variant="contained"
                    >
                        {data.forwardButtonText ?? 'Confirm'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </ConfirmationModalContext.Provider>
    );
};
