import { GridPinnedColumns, GridFilterItem } from '@mui/x-data-grid-pro';
import { IRecordType } from 'types/recordType';
import { ITenant } from 'types/tenant';
import { IProblemCode, IRecordStatus } from 'ui-component/RecordView/types';
import { ListObject } from 'views/backoffice/CustomLists/types';
import { IUser } from 'views/backoffice/users/types';

export interface IHeaderFields {
    tenantId: number;
    recordTypeId: number;
    description: string;
    issuedBy: string;
    issuedTo: string;
    status: unknown;
    createdBy: unknown;
    receivedBy: unknown;
    approvedBy: unknown;
    enabled: unknown;
    createdAt: unknown;
    updatedAt: unknown;
    'Discount Due Date': unknown;
    'Original File': true;
    'Discount Terms': true;
    'Freight ': unknown;
    'Freight 2': unknown;
    'Invoice Total': unknown;
    'Product Subtotal': unknown;
    'Sales Tax': unknown;
    'Shipping Method': unknown;
    Tariff: unknown;
    Terms: unknown;
}

/**
 * @deprecated
 */
export interface IValueGetterList {
    issuedBy?: (params: any) => any;
    issuedTo?: (params: any) => any;
    createdBy?: (params: any) => any;
    tenantId?: (params: any) => any;
    recordTypeId?: (params: any) => any;
    receivedBy?: (params: any) => any;
    approvedBy?: (params: any) => string;
    recordType?: (params: any) => string;
    tenant?: (params: any) => string;
    status?: (params: any) => string;
}

export interface IList {
    filters?: any;
    handleEditClick?: () => void;
    handleUpdateRow?: () => void;
    quickFilter?: any;
    order?: any;
    setOrder?: any;
    orderBy?: any;
    setOrderBy?: any;
    page?: any;
    rowsPerPage?: any;
    setRowsPerPage?: any;
    setRowCount?: any;
}

export interface IGridStatus {
    id: number;
    enable: boolean;
    name: string;
}

export interface IFindGridPreference {
    findUserGridPreferences: IGridPreference[];
}

export interface ISaasMyGridPreference {
    SaasMyGridPreferences: IGridPreference[];
}
export interface IUpdateGridPreference {
    updateUserGridPreferences: IGridPreference;
}

export interface IUpdateAllUserPreference {
    updateManyUserGridPreferences: IGridPreference;
}

export interface IRegisterUserPreference {
    registerUserGridPreferences: IGridPreference;
}

export interface IGridPreference {
    id: string | number;
    tenantId?: number;
    gridName: string;
    enabled?: boolean;
    savedName: string;
    isDefault: boolean;
    createdAt?: string;
    updatedAt?: string;
    role?: number;
    gridOptions: IGridOption[];
    [key: string]: any;
}

export interface IGridOption {
    orderedFields: string[];
    hiddenFields: string[];
    filterModel: IFilterModel[] & Partial<GridFilterItem>[];
    pinnedColumns?: (GridPinnedColumns & { __typename: string }) | null;
    densityFactor?: number | null;
    pageSize?: number | null;
}

export interface IFilterModel {
    columnField: string;
    operatorValue: string[];
    value: string[];
}
export interface IGridNameAndTenantId {
    gridName: string;
    tenantId: number;
}

export interface IGetRoleAccess {
    getRoleAccessToRecordFields: IRoleAccess[];
}

export interface IFindRoleAccess {
    findRoleAccessToRecordFields: IRoleAccess[];
}

export interface FindRoleAccessVariables {
    data: {
        ids?: number[];
        roles?: number[];
        menuItems?: number[];
        recordFields?: number[];
        recordFieldNames?: string[];
        enabled?: boolean;
        recordTypes?: number[];
        accesses?: FieldAccess[];
    };
}

export enum FieldAccess {
    NOT_ALLOWED = 'NotAllowed',
    EDITABLE = 'Editable',
    VIEW_ONLY = 'ViewOnly'
}

export interface IRoleAccess {
    access: string;
    enabled: boolean;
    id: string;
    order: number;
    recordFieldName: string;
    updatedAt: string;
    recordField: IRoleAccessChilds;
    recordType: IRoleAccessChilds;
    role: IRoleAccessChilds;
}

export interface IRoleAccessChilds {
    id: string;
    name: string;
}

export interface IGetRecordHeadersFiltered {
    filterAndSortRecordHeader: {
        records: IRecordHeaders[];
        total: number;
    };
}

export interface IGetRecordHeaders {
    findRecordHeader: {
        records: IRecordHeaders[];
        total: number;
    };
}

export interface RecordHeadersVariables {
    data: {
        id?: number;
        tenant?: number;
        recordType?: number;
        recordNumber?: string;
        assignedTo?: number;
        receivedAt?: string;
        approvedAt?: string;
        createdBy?: number;
        receivedBy?: number;
        approvedBy?: number;
        enabled?: boolean;
        additionalFields?: unknown[];
        ids?: number[];
        tenants?: number[];
        recordTypes?: number[];
        recordNumbers?: number[];
        receiverUsers?: number[];
        creatorUsers?: number[];
        approverUsers?: number[];
        assignedUsers?: number[];
        statusIds?: number[];
        problemCodeIds?: number[];
        recordFileIds?: number[];
    };
    pagination: {
        limit: number;
        offset: number;
    };
}

export interface IRecordHeaders {
    id: string;
    tenant: Pick<ITenant, 'id' | 'name'>;
    recordType: IRecordType;
    recordFile: Partial<IRecordFile>;
    statusId: Pick<IRecordStatus, 'id' | 'name'>;
    problemCodeId: Pick<IProblemCode, 'id' | 'name'> | null;
    createdAt: string;
    receivedAt: string;
    approvedAt: string | null;
    updatedAt: string;
    createdBy: Pick<IUser, 'id' | 'name'>;
    receivedBy: Pick<IUser, 'id' | 'name'> | null;
    approvedBy: Pick<IUser, 'id' | 'name'> | null;
    assignedTo: Pick<IUser, 'id' | 'name'> | null;
    targetTransmissionTenant: Pick<ITenant, 'id' | 'name'> | null;
    recordNumber: string | null;
    enabled: boolean;
    additionalFields: IAditionalFields[];
}

export interface IRecordFile {
    id: string;
    tenant: ITenant;
    fileBucketId: string;
    originalFileName: string;
    enabled: boolean;
    isShared: boolean;
    hashPublic: string;
    createdAt: string;
    updatedAt: string;
}

// TODO: Unify this definition with the one that is in src\views\TenantProfileSettings\components\types.tsx
export interface IObjectValues {
    value: string;
    tag: string;
    objectProperty: {
        id: string;
        name: string;
        isDisplayable: boolean;
        dataType: string;
        order: number;
    };
}
export interface IObjectValue {
    id: string;
    objectValues: IObjectValues[];
}

export interface IAditionalFields {
    id: string;
    tag: string;
    objectValue: IObjectValue | null;
    value: any;
}

export interface IGetRecordFields {
    getSaasRecordFieldsByRecordTypes: IRecordFieldsList;
}

export interface IObjectDefinition {
    createdAt: string;
    description: string | null;
    enabled: boolean;
    id: string | number;
    name: string;
    updatedAt: string;
}

export interface IRecordField {
    objectDefinition?: any;
    description: string;
    visibility: 'standard' | 'optional' | 'Standart' | 'true' | 'standart';
    dataType: FieldDataTypeEnum;
    headerField: boolean;
    listType?: ListObject | null;
    recordTypeId?: number;
    required: boolean;
    gridEditable: boolean;
    id: string | number;
}

export interface IRecordFieldWithFieldName extends IRecordField {
    fieldName: string;
}

export interface IRecordFieldsList {
    id: IRecordField;
    tenant: IRecordField;
    recordType: IRecordField;
    description: IRecordField;
    status: IRecordField;
    statusId: IRecordField;
    createdBy: IRecordField;
    receivedBy: IRecordField;
    approvedBy: IRecordField;
    enabled: IRecordField;
    createdAt: IRecordField;
    updatedAt: IRecordField;
    recordNumber: IRecordField;
    resultItemId: IRecordField;
    issuedByCompanyName: IRecordField;
    issuedToCompanyName: IRecordField;
    poNumber: IRecordField;
    problemCode: IRecordField;
    problemCodeId: IRecordField;
    validationRequired: IRecordField;
    assignedTo: IRecordField;
    recordDate: IRecordField;
    recordCreateDate: IRecordField;
    receivedAt: IRecordField;
    dueDate: IRecordField;
    approvedAt: IRecordField;
    'Received Time': IRecordField;
    'Original File': IRecordField;
    'Discount Terms': IRecordField;
    'Freight 2': IRecordField;
    Freight: IRecordField;
    'Product Subtotal': IRecordField;
    'Sales Tax': IRecordField;
    'Discount Due Date': IRecordField;
    'Shipping Method': IRecordField;
    Tariff: IRecordField;
    Terms: IRecordField;
    ItemId: IRecordField;
    'Document Full Text': IRecordField;
    'Date Captured': IRecordField;
    'Vendor Sales Order Number': IRecordField;
    'Discount Amount': IRecordField;
    Sensitivity: IRecordField;
    Importance: IRecordField;
    'Process Name': IRecordField;
    Comments: IRecordField;
    name: IRecordField;
    'name 35': IRecordField;
    'Test Field DJW': IRecordField;
    OctTest: IRecordField;
    'Test 922 911 fer': IRecordField;
    [key: string]: IRecordField;
}
export type UserGridPreferencesRegisterVariables = {
    data: {
        userId: number;
        tenantId: number;
        gridName: string;
        gridOptions: IGridOption[];
        enabled: boolean;
        savedName: string;
        isDefault: boolean;
    };
};

export type UserGridPreferencesUpdateVariables = {
    data: {
        id: number;
        userId?: number;
        tenantId?: number;
        gridName?: string;
        gridOptions?: IGridOption[];
        enabled?: boolean;
        savedName?: string;
        isDefault?: boolean;
    };
};

export interface FindRecordHeaderWithFilteringVariables {
    data: {
        tenantId: number;
        recordTypeId: number;
        enabled?: boolean;
        filters?: FilterRecordHeaderInput;
    };
    pagination: {
        limit: number;
        offset: number;
    };
}

export interface FilterRecordHeaderInput {
    ids?: number[];
    recordNumber?: FilterAndSortNumber;
    status?: FilterAndSortObject;
    problemCode?: FilterAndSortObject;
    enabled?: boolean;
    approvedAt?: FilterAndSortDate;
    receivedAt?: FilterAndSortDate;
    createdAt?: FilterAndSortDate;
    updatedAt?: FilterAndSortDate;
    additionalFields?: FilterAndSortAdditionalFields[];
    createdBy?: FilterAndSortObject;
    receivedBy?: FilterAndSortObject;
    approvedBy?: FilterAndSortObject;
    assignedTo?: FilterAndSortObject;
}

export type FilterAndSortString = {
    filters: FilterString[];
    sort: SortRecords;
};

export type FilterString = {
    logicOperator: filterOperatorEnum;
    priority: number;
    filterCriteria: FilterStringCriteriaEnum;
    value?: string;
    values?: string[];
};

export type FilterAndSortNumber = {
    filters: FilterNumber[];
    sort: SortRecords;
};

export type FilterNumber = {
    logicOperator: filterOperatorEnum;
    priority: number;
    filterCriteria: FilterNumberCriteriaEnum;
    value?: number;
    values?: number[];
};

export type FilterAndSortObject = {
    filters: FilterObject[];
    sort: SortRecords;
};

export type FilterObject = {
    logicOperator: filterOperatorEnum;
    priority: number;
    filterCriteria: FilterObjectCriteriaEnum;
    value?: number;
    values?: number[];
};

export type FilterAndSortDate = {
    filters: FilterDate[];
    sort: SortRecords;
};

export type FilterDate = {
    logicOperator: filterOperatorEnum;
    priority: number;
    filterCriteria: FilterDateCriteriaEnum;
    value?: string;
    values?: string[];
};

export type FilterAndSortAdditionalFields = {
    recordAdditionalFieldByTypeId: number;
    filters: FilterAdditionalField[];
    sort: SortRecords;
};

export type FilterAdditionalField = {
    logicOperator: filterOperatorEnum;
    priority: number;
    filterCriteria: filterOperatorEnum;
    value?: string;
    values?: string[];
};

export type SortRecords = {
    priority: number;
    orderCriteria: orderCriteriaEnum;
};

export enum orderCriteriaEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

export enum filterOperatorEnum {
    OR = 'OR',
    AND = 'AND'
}

export enum FilterStringCriteriaEnum {
    contains = 'contains',
    equals = 'equals',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
    isAnyOf = 'isAnyOf',
    notEqual = 'notEqual'
}

export enum FilterNumberCriteriaEnum {
    equals = 'equal',
    notEqual = 'notEqual',
    greaterThanOrEqual = 'greaterOrEqual',
    lessThanOrEqual = 'lessOrEqual',
    greaterThan = 'greaterThan',
    lessThan = 'lessThan',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
    isAnyOf = 'isAnyOf'
}

export enum FilterDateCriteriaEnum {
    is = 'is',
    isNot = 'isNot',
    isAfter = 'isAfter',
    isOnOrAfter = 'isOnOrAfter',
    isBefore = 'isBefore',
    isOnOrBefore = 'isOnOrBefore',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty'
}

export enum FilterObjectCriteriaEnum {
    is = 'is',
    isNot = 'isNot',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
    isAnyOf = 'isAnyOf',
    hasAnyOf = 'hasAnyOf',
    has = 'has',
    hasNot = 'hasNot'
}

export enum FilterCheckboxCriteriaEnum {
    isChecked = 'isChecked',
    isNotChecked = 'isNotChecked'
}

export enum FilterOperatorEnum {
    OR = 'OR',
    AND = 'AND'
}

export type GetTargetTenantsByRecordType = {
    getTargetTenantsByRecordType: TargetTenant[];
};

export type GetTargetTenantsByRecordTypeVariables = {
    data: {
        recordTypeId: number;
    };
};

export type TargetTenant = {
    mappingDetails: TargetTenantMappingDetails[];
    targetTenantToTransmit: Pick<ITenant, 'id' | 'name' | 'enabled'>;
};

export type TargetTenantMappingDetails = {
    recordTypeToTransmit: Pick<IRecordType, 'id' | 'name'>;
    transferSettings: TransferSettings;
};

export type TransferSettings = {
    sourceErrorStatusValue: string;
    sourceStatusField: Pick<IAditionalFields, 'id' | 'value'>;
    sourceSuccessStatusValue: string;
    targetRecordType: Pick<IRecordType, 'id' | 'name'>;
    targetStatusField: Pick<IAditionalFields, 'id' | 'value'>;
    targetStatusValue: string;
    triggerField: Pick<IAditionalFields, 'id' | 'value'>;
    triggerValue: string;
};

export type RecordTypeSummarize = {
    id: string;
    name: string;
    isSuper: boolean;
    relatedRecordTypes: { id: string; name: string }[];
};

export type IFindRecordTypeSummarize = {
    findRecordType: RecordTypeSummarize[];
};

export const FIELD_DATATYPES = {
    string: 'string',
    number: 'number',
    date: 'date',
    dropdown: 'dropdown',
    dropDown: 'dropDown',
    textarea: 'textarea',
    datetime: 'datetime',
    time: 'time',
    currency: 'currency',
    checkbox: 'checkbox',
    multiselect: 'multiselect',
    'multiselect-checkbox': 'multiselect-checkbox',
    attachment: 'attachment',
    url: 'url',
    object: 'object'
} as const;

export type FieldDataTypeEnum = keyof typeof FIELD_DATATYPES;

/**
 * These are the base field for all the record types, ifa new one is added
 * in DB should be add it here too
 */
export const BASE_FIELDS = [
    'id',
    'tenant',
    'recordType',
    'statusId',
    'createdBy',
    'receivedBy',
    'approvedBy',
    'enabled',
    'createdAt',
    'updatedAt',
    'recordNumber',
    'problemCodeId',
    'assignedTo',
    'receivedAt',
    'approvedAt',
    'targetTenant',
    'targetTransmissionTenant'
] as const;

export type BaseFieldsEnum = (typeof BASE_FIELDS)[number];
