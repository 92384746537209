import { useTheme } from '@mui/styles';
import { IAppModule } from '../types';
import { Divider, List, Typography } from '@mui/material';
import AppSections from '../AppSections';

interface AppModuleListProps {
    filteredMenuData: IAppModule[];
}

const AppModuleList = ({ filteredMenuData }: AppModuleListProps) => {
    const theme = useTheme();
    return filteredMenuData.length ? (
        <>
            {filteredMenuData
                .filter((item) => item.enabled)
                .map((item) => (
                    <>
                        <List
                            key={item.id}
                            subheader={
                                item.name && (
                                    <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                        {item.name}
                                    </Typography>
                                )
                            }
                        >
                            {item.appSections
                                .filter((section) => section.enabled)
                                .map((section) => (
                                    <AppSections section={section} key={section.id} parentRoute={`${item.route}`} />
                                ))}
                        </List>
                        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                    </>
                ))}
        </>
    ) : null;
};

export default AppModuleList;
