import { RoleItemActions } from 'types/layout';
import { IAppModule, IMenuItemWithFullPath } from './MenuList/types';

export const getFilteredMenu = (menus: IAppModule[], myMenuItemsIds: number[]) =>
    menus.map((saasMenu) => ({
        ...saasMenu,
        appSections: saasMenu.appSections.map((appSection) => ({
            ...appSection,
            menuItems: appSection.menuItems.filter((menuItemEl) => myMenuItemsIds?.includes(Number(menuItemEl.id)))
        }))
    }));

export const getAllMenuItems = (list: IAppModule[]): IMenuItemWithFullPath[] =>
    // @ts-ignore
    list.reduce((acc, el) => {
        const menuItems = el.appSections
            .map((section) =>
                section.menuItems.map((menuItem) => ({ ...menuItem, fullPath: `${el.route}/${section.route}/${menuItem.route}` }))
            )
            .flat();
        return [...acc, ...menuItems];
    }, []);

export const getMyMenuItems = (myItemActions: RoleItemActions[]) =>
    myItemActions.filter((el) => el.menuItemActions.find((action) => Number(action.id) === 1)).map((el) => Number(el.menuItem.id));
