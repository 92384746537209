import { Box, Grid, Typography, useTheme } from '@mui/material';
import { IconFileUpload } from '@tabler/icons';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

export type UploadBannerProps = {
    onFileChange: (file: File) => void;
};
export const UploadBanner = ({ onFileChange }: UploadBannerProps) => {
    const theme = useTheme();
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        onDrop: (acceptedFiles) => {
            onFileChange(acceptedFiles[0]);
        }
    });
    return (
        <Box sx={{ width: '100%' }}>
            <input {...getInputProps()} />
            <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '94px',
                    borderRadius: '8px',
                    border: 'solid 1px',
                    borderColor: theme.palette.secondary.main
                }}
            >
                <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <Grid item xs="auto">
                        <IconFileUpload size={24} color={theme.palette.secondary.main} />
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="h5" color="secondary" sx={{ ml: 2, fontSize: '14px' }}>
                            <FormattedMessage id="attachment.uploadBanner" />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
