/* eslint-disable react/no-danger */
import { Grid, Tooltip, Typography } from '@mui/material';
import { ITenant } from 'types/queries';
import { useCommentUsers } from 'ui-component/RecordView/CommentsPanel/hooks';
import { formatAbsolute, formatDateIfNeeded, renderComment } from 'ui-component/RecordView/CommentsPanel/utils';

export type CommentViewProps = {
    userId: number;
    tenant: Pick<ITenant, 'id' | 'name'>;
    content: string;
    createdAt: string;
};
export const CommentView = ({ userId, tenant, content, createdAt }: CommentViewProps) => {
    const { usersById } = useCommentUsers();
    return (
        <Grid container>
            <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                <Grid container item xs="auto" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: 'ED6C02' }} fontSize="16px">
                        {usersById[userId]?.name}
                    </Typography>
                    <Typography variant="caption" color="secondary" sx={{ margin: '0 10px' }}>
                        •
                    </Typography>
                    <Typography variant="subtitle1" color="secondary" fontSize="16px">
                        {tenant.name}
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Tooltip placement="top" title={formatAbsolute(createdAt)} enterDelay={300} leaveDelay={200}>
                        <Typography variant="caption" color="secondary" sx={{ cursor: 'default' }}>
                            {formatDateIfNeeded(createdAt)}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" fontSize="14px" color="#54595E" sx={{ '& p': { my: '0 !important' } }}>
                    <div dangerouslySetInnerHTML={{ __html: renderComment(content) }} />
                </Typography>
            </Grid>
        </Grid>
    );
};
