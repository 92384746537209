import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { orderListValuesById } from 'views/backoffice/CustomLists/utils';

export const useCustomListValues = (listIds: number[]) => {
    const [findListValue, { loading, data: listValueData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES, {
        variables: { data: { listIds } },
        fetchPolicy: 'no-cache'
    });

    const listValuesByListId = useMemo(() => {
        const baseListValues = listIds.reduce<Record<number, ListValue[]>>((acc, id) => ({ ...acc, [id]: [] }), {});
        const values = listValueData?.findListValues ?? [];
        return { ...baseListValues, ...orderListValuesById(values) };
    }, [listIds, listValueData?.findListValues]);

    useEffect(() => {
        if (listIds.length) findListValue();
    }, [findListValue, listIds.length]);

    return { loading, listValuesByListId };
};
