import AWS from 'aws-sdk';
import { AWS_API } from 'config';

export type CSVOpportunity = {
    description?: string;
    spaOppNumber?: string;
    account?: string;
    accountType?: string;
    quote?: string;
    contactName?: {
        last?: string;
        first?: string;
    };
    contactEmail?: string;
    estimatedClose?: string;
    totalNet?: string;
    totalList?: string;
    productList?: string;
    createDate?: string;
    spaApprovalDate?: string;
    owner?: string;
    accountManager?: string;
    city?: string;
    state?: string;
    status?: string;
    federal?: string;
    repIndividualUserID?: string;
    customer?: string;
};

export const S3Uploader = async (file: File): Promise<string> => {
    // AWS S3 Configuration
    AWS.config.update({
        accessKeyId: AWS_API.accessKeyId,
        secretAccessKey: AWS_API.secretAccessKey
    });
    const myBucket = new AWS.S3({
        params: { Bucket: AWS_API.s3Bucket },
        region: AWS_API.region
    });
    const params = {
        Body: file,
        Bucket: AWS_API.s3Bucket,
        Key: file.name
    };

    await myBucket.putObject(params).send((err, data) => {
        if (err) console.error('error uploading file: ', err);
    });

    return params.Key;
};

export const S3ImgUrlGetter = async (key: string): Promise<string> => {
    // AWS S3 Configuration
    AWS.config.update({
        accessKeyId: AWS_API.accessKeyId,
        secretAccessKey: AWS_API.secretAccessKey
    });
    const myBucket = new AWS.S3({
        params: { Bucket: AWS_API.s3Bucket },
        region: AWS_API.region
    });

    const result = await myBucket.getSignedUrlPromise('getObject', { Bucket: AWS_API.s3Bucket, Key: key });
    return result;
};

/**
 * Retrieves a CSV file from S3 and maps its contents to an array of CSVOpportunity objects.
 *
 * @param {string} fileKey - The key of the file in the S3 bucket. Defaults to 'ais/AllOpportunities.csv'.
 * @return {Promise<CSVOpportunity[]>} - A promise that resolves to an array of CSVOpportunity objects.
 */
export const S3CSVOpportunityMapper = async (fileKey = 'ais/AllOpportunities.csv'): Promise<CSVOpportunity[]> => {
    const fileUrl = await S3ImgUrlGetter(fileKey);
    // Use this for offline testing and put the file in public folder
    // const file = await fetch('/AllOpportunitiesNew.csv');
    const file = await fetch(fileUrl);
    const blob = await file.text();
    const opportunities: CSVOpportunity[] = [];
    blob.split('\n').forEach((line, index) => {
        if (index > 0) {
            // Get all qouted parts in order to prevent comma injection
            const re = /"(.*?)"/g;
            const result: string[] = [];
            let current;
            while ((current = re.exec(line))) {
                result.push(current?.pop() ?? '');
            }
            let lineParsed = line;
            result.forEach((part) => {
                lineParsed = lineParsed.replaceAll(part, part.replaceAll(',', ';'));
            });
            const opportunity = lineParsed.split(',');
            const contactName = opportunity[5]?.split(';') ?? ['', ''];
            opportunities.push({
                description: opportunity[0]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                spaOppNumber: opportunity[1]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                account: opportunity[2]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                accountType: opportunity[3]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                quote: opportunity[4]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                contactName: {
                    last: contactName[0]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                    first: contactName[1]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? ''
                },
                contactEmail: opportunity[6]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                estimatedClose: opportunity[7]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                totalNet: opportunity[8]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                totalList: opportunity[9]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                productList: opportunity[10]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                createDate: opportunity[11]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                spaApprovalDate: opportunity[12]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                owner: opportunity[13]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                accountManager: opportunity[14]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                city: opportunity[15]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                state: opportunity[16]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                status: opportunity[17]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                federal: opportunity[18]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                repIndividualUserID: opportunity[19]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? '',
                customer: opportunity[20]?.replaceAll('"', '').replaceAll(';', ',').trim() ?? ''
            });
        }
    });

    return opportunities;
};
