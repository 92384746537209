import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { IUserDataToken } from 'utils/types';
import { S3Uploader } from 'utils/S3Uploader';
import { IAditionalFields } from 'ui-component/records/types';
import { List, NoFilesIcon, UploadBanner } from './components';
import { AttachmentRow } from './types';
import { IFormatDataValues } from '../types';

export type AttachmentsProps = {
    fullHeight?: boolean;
    attachmentFieldId?: string | number;
    attachmentData?: IAditionalFields;
    onUpdateAttachment: (payload: IFormatDataValues) => Promise<boolean>;
};

export const Attachments = ({ fullHeight, attachmentFieldId, attachmentData, onUpdateAttachment }: AttachmentsProps) => {
    const token = localStorage.getItem('backend_jwt') ?? '';
    const userData: IUserDataToken = jwt_decode(token);
    const dispatch = useDispatch();
    const [rows, setRows] = useState<AttachmentRow[]>([]);

    const handleSelectFile = async (file: File) => {
        try {
            const key = await S3Uploader(file);

            const newRow = {
                id: uuidv4(),
                name: file.name,
                description: '',
                fileType: file.type,
                fileSize: (file.size / 1024).toFixed(2),
                createdAt: new Date().toISOString(),
                createdBy: userData.userId,
                fileUrl: key
            };

            const newValue = [...rows, newRow];

            const payload = attachmentData
                ? { tag: attachmentData.tag, value: JSON.stringify(newValue) }
                : { tag: `;;${attachmentFieldId}`, value: JSON.stringify(newValue), objectValuesByProperty: undefined };

            const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);
            if (res) {
                setRows(newValue);

                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'File Uploaded',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else throw new Error('Save attachment failed');
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Save attachment failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    const handleUpdateRow = async (newRow: AttachmentRow) => {
        if (!attachmentData) return;
        const newValue = rows.map((r) => (r.id === newRow.id ? newRow : r));
        const payload = { tag: attachmentData.tag, value: JSON.stringify(newValue) };

        const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);

        if (res) {
            setRows(newValue);

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'File Updated',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } else
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Update file failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
    };

    const handleDeleteRow = async (row: AttachmentRow) => {
        if (!attachmentData) return;
        const newValue = rows.filter((r) => r.id !== row.id);
        const payload = { tag: attachmentData.tag, value: JSON.stringify(newValue) };

        const res = await onUpdateAttachment({ baseData: {}, additionalFields: [payload] } as IFormatDataValues);

        if (res) {
            setRows(newValue);

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'File Deleted',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } else
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Delete file failed',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
    };

    useEffect(() => {
        if (attachmentData) setRows(JSON.parse(attachmentData.value) as AttachmentRow[]);
    }, [attachmentData]);

    return (
        <section id="record-attachment-section" style={{ height: 'calc(100% - 60px)' }}>
            <UploadBanner onFileChange={handleSelectFile} />
            <Grid container justifyContent="center" alignItems="center" sx={{ height: 'calc(100% - 94px)' }}>
                {!rows.length && (
                    <Grid item xs="auto" sx={{ textAlign: 'center' }}>
                        {fullHeight && <NoFilesIcon />}
                        <Typography variant="h5" color="#54595E" fontSize="20px" fontWeight={400}>
                            <FormattedMessage id="attachment.noFiles" />
                        </Typography>
                    </Grid>
                )}
                {!!rows.length && (
                    <Grid item xs={12} height="100%">
                        <List rows={rows} onDeleteRow={handleDeleteRow} onUpdateRow={handleUpdateRow} />
                    </Grid>
                )}
            </Grid>
        </section>
    );
};
