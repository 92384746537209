import { gql } from '@apollo/client';

export const QUERY_GET_LISTS = gql`
    query FindList($data: FindListsInput!) {
        findList(data: $data) {
            createdAt
            updatedAt
            id
            enabled
            listName
            description
            dataType
            origin
            objectDefinition {
                id
                name
                objectProperties {
                    id
                    dataType
                    isRequired
                    name
                    order
                    enabled
                }
            }
        }
    }
`;

export const QUERY_GET_LIST_VALUES = gql`
    query FindListValues($data: FindListsValuesInput!) {
        findListValues(data: $data) {
            createdAt
            enabled
            id
            order
            userValue {
                id
                lastName
                firstName
                name
                phone
                email
            }
            listId {
                id
            }
            dynamicObjectValue {
                id
                objectDefinition {
                    id
                    objectProperties {
                        id
                        name
                        order
                        isDisplayable
                        isRequired
                        enabled
                        dataType
                    }
                }
                objectValues {
                    value
                    tag
                    objectProperty {
                        id
                        dataType
                        name
                        isDisplayable
                        order
                    }
                }
            }
            updatedAt
            value
        }
    }
`;
