import { IRoleAccess } from '../types';

/**
 * Non editable fields define from the database
 */
export const NON_EDITABLE_FIELDS = ['id', 'tenant', 'recordType', 'enabled', 'createdBy', 'createdAt', 'updatedAt'] as const;

/**
 *  Returns true is the field could be editable based on
 * access data and not editable fields array
 *
 * @param key {String} key of a Record field
 * @param fieldAccessData {IRoleAccess[]} list of access data
 * @param recordTypeId {Number}
 * @param roleId {Number}
 */
export const getEditableField = (
    key: string,
    fieldAccessData: IRoleAccess[] | undefined,
    recordTypeId?: number,
    roleId?: number | null
) => {
    if (!fieldAccessData || !recordTypeId || !roleId || (NON_EDITABLE_FIELDS as ReadonlyArray<string>).includes(key)) return false;

    const fieldsEditableList = fieldAccessData
        .filter((access) => Number(access.recordType.id) === recordTypeId)
        .filter((access) => Number(access.role.id) === roleId)
        .filter((access) => access.access.toLowerCase() === 'editable');

    if (!fieldsEditableList.length) return false;

    return !!fieldsEditableList.find((field) => field.recordFieldName === key);
};

/**
 * Returns a function to check if a field is allowed,
 * that function returns true if the field is allowed
 * and false if not
 *
 * @param fieldAccessData {IRoleAccess[]} field access stored
 * @param recordTypeId {Number}
 * @param roleId {Number}
 */
export const filterNotAllowedFields = (fieldAccessData?: IRoleAccess[], recordTypeId?: number, roleId?: number | null) => (key: string) => {
    if (!fieldAccessData || !recordTypeId || !roleId) return true;
    const notAllowedFieldList = fieldAccessData
        .filter((access) => Number(access.recordType.id) === recordTypeId)
        .filter((access) => Number(access.role.id) === roleId)
        .filter((access) => access.access.toLowerCase() === 'notallowed');

    if (!notAllowedFieldList.length) return true;

    return !notAllowedFieldList.find(({ recordFieldName }) => recordFieldName === key);
};

export const editableFields: any = {
    description: true,
    status: true,
    receivedBy: true,
    approvedBy: true,
    enabled: true,
    recordNumber: true,
    issuedByCompanyName: true,
    issuedToCompanyName: true,
    poNumber: true,
    problemCode: true,
    problemCodeId: true,
    validationRequired: true,
    assignedTo: true,
    'Discount Terms': true,
    'Freight 2': true,
    Freight: true,
    'Product Subtotal': true,
    'Sales Tax': true,
    'Discount Due Date': true,
    'Shipping Method': true,
    Tariff: true,
    Terms: true,
    Importance: true,
    name: true,
    'name 35': true
};
