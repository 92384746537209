import React, { useRef, useState } from 'react';
import { ExpandMore, HighlightOff, HighlightOffOutlined, History, Menu } from '@mui/icons-material';
import {
    Button,
    ClickAwayListener,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { LineItemIcon } from 'views/DocumentViewer/components/Icons';
import { IconMessageCircle2, IconPaperclip } from '@tabler/icons';
import Transitions from 'ui-component/extended/Transitions';
import { LogReportMode, PanelTitle } from '../utils';

export interface HeaderPanelProps {
    title: string;
    showHistory: boolean;
    toggleEditForm: () => void;
    toggleLogReport: () => void;
    changePanel: PanelTitle;
    setLogReportMode: (mode: LogReportMode) => void;
    isOpenForm: boolean;
    onShowFullHistory: () => void;
    isCreate?: boolean;
    onToggleLineItems?: () => void;
    isObjectPanel?: boolean;
    onGoBack?: () => void;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
}

export const HeaderPanel = ({
    title,
    showHistory,
    onShowFullHistory,
    toggleEditForm,
    changePanel,
    setLogReportMode,
    toggleLogReport,
    isOpenForm,
    isCreate,
    onToggleLineItems,
    onGoBack,
    isObjectPanel = false,
    onToggleComments,
    onToggleAttachments
}: HeaderPanelProps) => {
    const boxStyle = {
        // TODO: change this to use the theme
        backgroundColor: 'transparent',
        display: 'flex',
        padding: '19px 0px 0px 0px'
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    };
    const [logReportMenuOpen, setLogReportMenuOpen] = useState(false);
    const anchorRef = useRef<any>(null);

    const handleToggleLogReportMenu = () => {
        setLogReportMenuOpen((prevOpen) => !prevOpen);
    };

    const handleCloseReportMenu = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setLogReportMenuOpen(false);
    };

    return (
        <Box position="static" style={boxStyle} data-testid="header-panel">
            <List>
                <Typography
                    variant={isObjectPanel ? 'h4' : 'h3'}
                    component="div"
                    sx={(theme) => ({
                        paddingLeft: '10px',
                        color: theme.palette.secondary.main,
                        fontWeight: '500',
                        fontSize: '16px',
                        letterSpacing: '0.15px',
                        lineHeight: '24px'
                    })}
                >
                    {isObjectPanel && (
                        <Box
                            onClick={() => onGoBack?.()}
                            component="span"
                            sx={(theme) => ({ color: theme.palette.primary[400], cursor: 'pointer' })}
                        >
                            Details {`> `}
                        </Box>
                    )}
                    {title}
                </Typography>
            </List>
            {isCreate && (
                <Box sx={{ flexGrow: 1 }}>
                    <ToggleButtonGroup
                        sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end', '& button': { height: '100%' } }}
                    >
                        {isObjectPanel && (
                            <Tooltip title="Close Object Panel">
                                <IconButton
                                    onClick={() => onGoBack?.()}
                                    value="Close Object Panel"
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <HighlightOff />
                                </IconButton>
                            </Tooltip>
                        )}
                    </ToggleButtonGroup>
                </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
                <ToggleButtonGroup sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'flex-end', '& button': { height: '100%' } }}>
                    {onToggleComments && !isCreate && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Comments">
                                <IconButton
                                    onClick={onToggleComments}
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <IconMessageCircle2 />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {onToggleAttachments && !isCreate && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Attachments">
                                <IconButton
                                    onClick={onToggleAttachments}
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <IconPaperclip />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {!!onToggleLineItems && changePanel !== PanelTitle.LogReport && (
                        <Grid>
                            <Tooltip title="Line Items">
                                <IconButton
                                    onClick={onToggleLineItems}
                                    value="line items"
                                    size="medium"
                                    sx={(theme) => ({
                                        color: theme.palette.primary[400],
                                        backgroundColor: '#FFF',
                                        fontWeight: 600,
                                        height: '100%',
                                        '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                    })}
                                >
                                    <LineItemIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    {!isCreate && (
                        <>
                            {changePanel === PanelTitle.LogReport && (
                                <>
                                    <Button
                                        size="medium"
                                        color="primary"
                                        ref={anchorRef}
                                        onClick={handleToggleLogReportMenu}
                                        sx={(theme) => ({
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            borderRadius: '30px',
                                            '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                        })}
                                    >
                                        <Menu fontSize="medium" />
                                        <ExpandMore fontSize="medium" />
                                    </Button>
                                    <Popper
                                        onResize={null}
                                        onResizeCapture={null}
                                        placement="bottom-end"
                                        open={logReportMenuOpen}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                        sx={{
                                            border: `1px solid #D9D9D9`,
                                            borderRadius: '8px !important',
                                            zIndex: 9
                                        }}
                                        popperOptions={{
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 14]
                                                    }
                                                }
                                            ]
                                        }}
                                    >
                                        {({ TransitionProps }) => (
                                            <ClickAwayListener onClickAway={handleCloseReportMenu}>
                                                <Transitions in={logReportMenuOpen} {...TransitionProps}>
                                                    <Paper sx={{ borderRadius: '8px !important' }}>
                                                        {logReportMenuOpen && (
                                                            <List>
                                                                <ListItem divider disablePadding>
                                                                    <ListItemButton
                                                                        sx={(theme) => ({
                                                                            '&:hover': {
                                                                                color: theme.palette.primary[400],
                                                                                background: theme.palette.primary[300]
                                                                            }
                                                                        })}
                                                                        onClick={() => {
                                                                            setLogReportMode(LogReportMode.All);
                                                                            handleToggleLogReportMenu();
                                                                        }}
                                                                    >
                                                                        <ListItemText primary="Record History" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <ListItem divider disablePadding>
                                                                    <ListItemButton
                                                                        sx={(theme) => ({
                                                                            '&:hover': {
                                                                                color: theme.palette.primary[400],
                                                                                background: theme.palette.primary[300]
                                                                            }
                                                                        })}
                                                                        onClick={() => {
                                                                            setLogReportMode(LogReportMode.Objects);
                                                                            handleToggleLogReportMenu();
                                                                        }}
                                                                    >
                                                                        <ListItemText primary="Objects History" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <ListItem divider disablePadding>
                                                                    <ListItemButton
                                                                        sx={(theme) => ({
                                                                            '&:hover': {
                                                                                color: theme.palette.primary[400],
                                                                                background: theme.palette.primary[300]
                                                                            }
                                                                        })}
                                                                        onClick={() => {
                                                                            setLogReportMode(LogReportMode.LineItems);
                                                                            handleToggleLogReportMenu();
                                                                        }}
                                                                    >
                                                                        <ListItemText primary="Line items History" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <ListItem disablePadding>
                                                                    <ListItemButton
                                                                        sx={(theme) => ({
                                                                            '&:hover': {
                                                                                color: theme.palette.primary[400],
                                                                                background: theme.palette.primary[300]
                                                                            }
                                                                        })}
                                                                        onClick={() => {
                                                                            setLogReportMode(LogReportMode.Comments);
                                                                            handleToggleLogReportMenu();
                                                                        }}
                                                                    >
                                                                        <ListItemText primary="Comments History" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </List>
                                                        )}
                                                    </Paper>
                                                </Transitions>
                                            </ClickAwayListener>
                                        )}
                                    </Popper>
                                </>
                            )}
                            <Grid>
                                <Tooltip title="Log report">
                                    <IconButton
                                        onClick={toggleLogReport}
                                        value="Log report"
                                        size="medium"
                                        sx={(theme) => ({
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                        })}
                                    >
                                        {changePanel === PanelTitle.LogReport ? (
                                            <HighlightOffOutlined fontSize="medium" />
                                        ) : (
                                            <History fontSize="medium" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {isObjectPanel && (
                                <Tooltip title="Close Object Panel">
                                    <IconButton
                                        onClick={() => onGoBack?.()}
                                        value="Close Object Panel"
                                        size="medium"
                                        sx={(theme) => ({
                                            color: theme.palette.primary[400],
                                            backgroundColor: '#FFF',
                                            fontWeight: 600,
                                            '&:hover': { color: theme.palette.primary[400], backgroundColor: theme.palette.primary[300] }
                                        })}
                                    >
                                        <HighlightOff />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
};

export default HeaderPanel;
