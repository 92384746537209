/* eslint-disable no-underscore-dangle */
import { parseISO, differenceInHours, format, formatDistanceToNow } from 'date-fns';
import { RawDraftContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Comment } from './types';

/**
 * Returns and string HTML representation of the content
 *
 * @param contentStr {String} Content State stringified
 */
export const renderComment = (contentStr: string) => {
    const rawContent = JSON.parse(contentStr) as RawDraftContentState;
    const contentState = convertFromRaw(rawContent);
    return stateToHTML(contentState);
};

/**
 * Return a formatted date if is not yet
 *
 * @param dateStr {String} Date as string
 */
export const formatDateIfNeeded = (dateStr: string) => {
    if (!Date.parse(dateStr)) return dateStr;
    const hasMoreThanTwelveHoursAgo = validateHoursFromNow(dateStr, 12);
    return hasMoreThanTwelveHoursAgo
        ? formatAbsolute(dateStr)
        : formatDistanceToNow(new Date(dateStr), { addSuffix: true, includeSeconds: true });
};

/**
 * Returns an absolute format of the date
 *
 * @param dateStr {String}
 */
export const formatAbsolute = (dateStr: string) => {
    if (!Date.parse(dateStr)) return dateStr;
    return format(new Date(dateStr), 'MMM dd, yyyy, h:mm a');
};

/**
 * Returns an array with parents comments and child comments grouped by parent id
 *
 * @param commentList {Comment[]}
 */
export const generateCommentTree = (commentList: Comment[]) => {
    const enableComments = commentList.filter((el) => el.enabled);
    const parentComments = enableComments.filter((el) => !el.parentComment).sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    const childComments: Record<string, Comment[]> = {};

    for (const currentComment of enableComments) {
        const parentCommentId = currentComment.parentComment;
        if (parentCommentId) {
            if (childComments[parentCommentId]) childComments[parentCommentId] = [...childComments[parentCommentId], currentComment];
            else childComments[parentCommentId] = [currentComment];
        }
    }

    return { parentComments, childComments };
};

/**
 * Compares the current date with the given date and
 * returns true of false depending if the diference
 * is more of the hour limit given
 *
 * @param date {String} date to be compared
 * @param hourLimit {Number} maximun hour quantity
 */
export const validateHoursFromNow = (date: string, hourLimit: number) => {
    const dateobj = parseISO(date);
    const currentDateObj = new Date();

    const hours = differenceInHours(currentDateObj, dateobj);

    return hours > hourLimit;
};
