import IkeaLogo from 'assets/images/TenantsLogos/ikea.png';
import HermanLogo from 'assets/images/TenantsLogos/herman.png';
import dayjs from 'dayjs';
import { TypeTenant } from 'views/discover';

export const tenantListMock: (TypeTenant & { message: string })[] = [
    {
        id: 2,
        contact: 'Robert',
        email: 'contact@ikea.com',
        phone: '9542565588',
        about: 'Nova Furniture is where craftsmanship meets innovation. Our designs  blend aesthetic appeal with functional excellence. Our curated collections, from modern chic to timeless classics, are meticulously crafted to elevate your living experience. Committed to quality and sustainability, we ensure each piece enhances your home while contributing to a greener future. Discover the intersection of style and substance with Nova Furniture. ',
        city: 'Miami, FL',
        connectedAt: dayjs().format('YYYY/MM/DD hh:mm a'),
        name: 'Ikea',
        profilePic: IkeaLogo,
        cardBg: 'https://picsum.photos/id/2/200/300',
        slogan: 'The Wonderful Everyday is Coming',
        tenantType: 'Dealership',
        address: '225 Lincoln Street, Miami, FL',
        tenantsInCommon: 4,
        connected: 'not-connected',
        message: 'Hey John! I would like to connect with you'
    },
    {
        id: 3,
        contact: 'John',
        email: 'contact@herman.com',
        phone: '9542565588',
        about: 'Nova Furniture is where craftsmanship meets innovation. Our designs  blend aesthetic appeal with functional excellence. Our curated collections, from modern chic to timeless classics, are meticulously crafted to elevate your living experience. Committed to quality and sustainability, we ensure each piece enhances your home while contributing to a greener future. Discover the intersection of style and substance with Nova Furniture. ',
        city: 'Miami, FL',
        connectedAt: dayjs().format('YYYY/MM/DD hh:mm a'),
        name: 'Herman Miller',
        profilePic: HermanLogo,
        cardBg: 'https://picsum.photos/id/3/200/300',
        slogan: 'Design For The Good Of All',
        tenantType: 'Distributor',
        address: '5478 Indian Trace, Weston, FL',
        tenantsInCommon: 4,
        connected: 'not-connected',
        message: 'Hey John! I would like to connect with you'
    }
];
