/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined, KeyboardArrowDown } from '@mui/icons-material';
import { Grid, IconButton, MenuItem, TextField, Divider } from '@mui/material';
import { ContentBlock, DraftHandleValue, Editor, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import React, { ElementType } from 'react';
import 'draft-js/dist/Draft.css';

export type EditorSectionProps = {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
};

export const EditorSection = ({ editorState, setEditorState }: EditorSectionProps) => {
    const editorRef = React.useRef<any>(null);

    const toggleBlockType = (blockType: string) => {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle: string) => {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleKeyCommand = (command: string, currentEditorState: EditorState, eventTimestamp: number): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(currentEditorState, command);
        if (newState) {
            onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const mapKeyToEditorCommand = (e: any) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return null;
        }
        return getDefaultKeyBinding(e);
    };

    const onChange = (newState: EditorState) => setEditorState(newState);

    return (
        <div className="RichEditor-root">
            <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} onToggleBlockType={toggleBlockType} />
            <Divider
                orientation="vertical"
                sx={{
                    width: '95%',
                    borderColor: '#D9D9D9',
                    margin: '0 auto 5px',
                    borderWidth: '1px'
                }}
            />
            <div onClick={() => editorRef.current?.focus()} style={{ padding: '0 10px 10px', color: 'black' }}>
                <Editor
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={onChange}
                    placeholder="Add a comment"
                    ref={editorRef}
                    spellCheck
                />
            </div>
        </div>
    );
};

export type InlineStyleControlsProps = {
    editorState: EditorState;
    onToggle: (style: string) => void;
    onToggleBlockType: (blockType: string) => void;
};

const InlineStyleControls = ({ editorState, onToggle, onToggleBlockType }: InlineStyleControlsProps) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="RichEditor-controls">
            <Grid container alignItems="center" sx={{ p: 1 }}>
                <Grid item xs="auto">
                    <TextField
                        variant="filled"
                        sx={{
                            '& .MuiInputBase-root': { fontWeight: 500, background: 'white' },
                            '& *': { color: '#37326E !important' },
                            '& .MuiSelect-select': { py: '4px !important' }
                        }}
                        InputProps={{ disableUnderline: true, hiddenLabel: true, sx: { borderRadius: '10px' } }}
                        SelectProps={{
                            sx: {
                                borderRadius: '10px',
                                '& .MuiSelect-icon': { color: '#37326E' }
                            },
                            IconComponent: KeyboardArrowDown
                        }}
                        color="secondary"
                        value={blockType}
                        onChange={(e) => onToggleBlockType(e.target.value)}
                        select
                    >
                        <MenuItem value="unstyled">Normal Text</MenuItem>
                        <MenuItem value="header-one">Heading 1</MenuItem>
                        <MenuItem value="header-two">heading 2</MenuItem>
                        <MenuItem value="header-three">Heading 3</MenuItem>
                        <MenuItem value="header-four">Heading 4</MenuItem>
                        <MenuItem value="header-five">Heading 5</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs="auto">
                    <Divider
                        orientation="vertical"
                        sx={{
                            borderColor: '#D9D9D9',
                            margin: '0 auto',
                            borderRightWidth: '1px',
                            width: '7px',
                            height: '30px',
                            marginRight: '5px'
                        }}
                    />
                </Grid>
                <Grid item xs="auto">
                    <StyleButton active={currentStyle.has('BOLD')} IconComponent={FormatBold} onToggle={() => onToggle('BOLD')} />
                </Grid>
                <Grid item xs="auto">
                    <StyleButton active={currentStyle.has('ITALIC')} IconComponent={FormatItalic} onToggle={() => onToggle('ITALIC')} />
                </Grid>
                <Grid item xs="auto">
                    <StyleButton
                        active={currentStyle.has('UNDERLINE')}
                        IconComponent={FormatUnderlined}
                        onToggle={() => onToggle('UNDERLINE')}
                    />
                </Grid>
                <Grid item xs="auto">
                    <Divider
                        orientation="vertical"
                        sx={{
                            borderColor: '#D9D9D9',
                            margin: '0 auto',
                            borderRightWidth: '1px',
                            width: '7px',
                            height: '30px',
                            marginRight: '5px'
                        }}
                    />
                </Grid>
                <Grid item xs="auto">
                    <StyleButton
                        active={blockType === 'unordered-list-item'}
                        IconComponent={FormatListBulleted}
                        onToggle={() => onToggleBlockType('unordered-list-item')}
                    />
                </Grid>

                <Grid item xs="auto">
                    <StyleButton
                        active={blockType === 'ordered-list-item'}
                        IconComponent={FormatListNumbered}
                        onToggle={() => onToggleBlockType('ordered-list-item')}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export type StyleButtonProps = {
    active: boolean;
    IconComponent: ElementType;
    onToggle: () => void;
};

const StyleButton = ({ active, onToggle, IconComponent }: StyleButtonProps) => {
    const className = `RichEditor-styleButton ${active ? 'RichEditor-activeButton' : ''}`;

    const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onToggle();
    };

    return (
        <IconButton
            sx={{ width: '40px', height: '32px', mx: '2px', bgcolor: active ? '#EBEEFE' : 'white' }}
            className={className}
            onMouseDown={handleToggle}
        >
            <IconComponent htmlColor="#37326E" sx={{ width: '24px', height: '24px' }} />
        </IconButton>
    );
};

const getBlockStyle = (block: ContentBlock) => {
    const value = block.getType();
    return value === 'blockquote' ? 'RichEditor-blockquote' : '';
};

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
    }
};
