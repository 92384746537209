import { useState } from 'react';
import { Avatar, Grid } from '@mui/material';
import { useCommentUsers } from '../hooks';
import { CommentField } from './CommentField';

export type NewCommentSectionProps = {
    onSendComment: (message: string) => void;
    onCancel?: () => void;
    initialFocus?: boolean;
    editMode?: boolean;
    initialContent?: string;
};

export const NewCommentSection = ({ onSendComment, onCancel, initialFocus, editMode, initialContent }: NewCommentSectionProps) => {
    const { currentUser } = useCommentUsers();
    const [isFocused, setIsFocused] = useState(initialFocus ?? false);

    const handleCancel = () => {
        setIsFocused(false);
        onCancel?.();
    };

    return (
        <Grid container alignItems="center" justifyContent="flex-end" sx={{ flexWrap: 'nowrap', width: '100%', py: '8px' }}>
            {!editMode && (
                <Grid item xs="auto" sx={{ mt: isFocused ? '-50px' : 0, textAlign: 'right' }}>
                    <Avatar
                        sx={(theme) => ({
                            width: 40,
                            height: 40,
                            background: 'white',
                            border: `solid 1px ${theme.palette.secondary.main}`,
                            color: theme.palette.secondary.main,
                            display: 'inline-flex',
                            mr: '12px'
                        })}
                    >
                        {currentUser?.name[0] || 'E'}
                    </Avatar>
                </Grid>
            )}
            <Grid item xs={editMode ? 12 : 'auto'} sx={{ maxWidth: 'calc(100% - 52px) !important', flexGrow: '1 !important' }}>
                <CommentField
                    isFocused={isFocused}
                    onBlur={handleCancel}
                    onFocus={() => setIsFocused(true)}
                    onSendComment={onSendComment}
                    initialContent={initialContent}
                />
            </Grid>
        </Grid>
    );
};
