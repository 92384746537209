/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions, Grid, OutlinedInput } from '@mui/material';
import { RootDialog } from 'views/pages/authentication/mfa-components/RootDialog';
import { useDropzone } from 'react-dropzone';
import { UploadPdfBanner } from 'views/CreateRecord/components/UploadPdfBanner';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { closeDialog, handleCancel, handleSubmit, templateMessage } from 'store/slices/discover-send-message-dialog';
import { PdfViewer } from './RecordView';
import { rotatePlugin } from '@react-pdf-viewer/rotate';

const SendMessageDialog = () => {
    const dispatch = useDispatch();
    const dialogProps = useSelector((state) => state.discoverSendMessageDialog);
    const { open, message, recipientId, readOnly } = dialogProps;
    const [inputValue, setInputValue] = useState(message);
    const theme = useTheme();
    const rotatePluginInstance = rotatePlugin();
    const [file, setFile] = useState<(File & { preview: string }) | null>();
    const [rootFile, setRootFile] = useState<File>();
    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: {
            'image/*': [],
            'application/pdf': []
        },
        onDrop: (acceptedFiles) => {
            setRootFile(acceptedFiles[0]);
            setFile({ ...acceptedFiles[0], preview: URL.createObjectURL(acceptedFiles[0]) });
        }
    });

    useEffect(() => {
        setInputValue(message);
    }, [message]);
    const handleClose = () => {
        setInputValue(templateMessage);
        setFile(null);
        dispatch(closeDialog());
    };

    const handleSubmitForm = () => {
        dispatch(handleSubmit());
        handleClose();
    };

    const handleCancelForm = () => {
        dispatch(handleCancel());
        handleClose();
    };

    return (
        <>
            <RootDialog maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="compatible-mfa-applications-modal" open={open}>
                <DialogTitle
                    sx={{ m: 0, p: 2, paddingBottom: '0 !important' }}
                    color={theme.palette.secondary.main}
                    variant="h3"
                    id="compatible-mfa-applications-modal"
                >
                    Get in Touch
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#CCC'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <OutlinedInput
                                multiline
                                readOnly={readOnly}
                                rows={15}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                type="text"
                                id="message"
                                name="message"
                                fullWidth
                                sx={{ marginBottom: '2rem' }}
                            />
                        </Grid>
                        {!readOnly &&
                            (file ? (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ ml: 0, mr: 0, height: '350px', width: '100%', border: '1px dashed', borderRadius: '20px' }}
                                >
                                    <PdfViewer url={file.preview} rotateInstance={rotatePluginInstance} />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ ml: 0, mr: 0, height: '189px', width: '100%', border: '1px dashed', borderRadius: '20px' }}
                                >
                                    <UploadPdfBanner rootProps={getRootProps} />
                                    <input accept=".jpg, .jpeg, .png, .gif, .svg, .pdf" {...getInputProps()} />
                                </Grid>
                            ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelForm} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button type="submit" onClick={handleSubmitForm} variant="contained" color="secondary">
                        Send
                    </Button>
                </DialogActions>
            </RootDialog>
        </>
    );
};

export default SendMessageDialog;
