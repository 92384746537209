/* eslint-disable no-case-declarations */
import * as yup from 'yup';
import { ListForm } from './components';
import { CreateListValuesVariables, ListDataTypeEnum, ListValue, UpdateListValuesVariables } from './types';
import { ObjectProperty } from '../CustomObjects/types';
import { IRecordField } from 'ui-component/records/types';
import { capitalize } from 'utils/stringHelpers';
import { getDateWithoutTZ } from 'ui-component/records/utils/dateHelpers';
import { format } from 'date-fns';
import { DynamicObject, ObjectProperties, ObjectValue } from 'views/TenantProfileSettings/components/types';
import dayjs from 'dayjs';

export const generateUserValuesData = (ids: number[], existingData: ListValue[], listId: number) => {
    const dataArr:
        | (
              | { isNew: true; data: CreateListValuesVariables['data'] }
              | {
                    isNew: false;
                    data: UpdateListValuesVariables['data'];
                }
          )[] = [];

    // when the user id is not in the array it means we have to disabled
    const deletedUsersValue = existingData.filter((el) => !ids.includes(Number(el.userValue.id)));

    const existingDataNumered = existingData.reduce<Record<number, ListValue>>(
        (acc, el) => ({ ...acc, [Number(el.userValue.id)]: el }),
        {}
    );

    for (const [index, id] of ids.entries()) {
        const existingValue = existingDataNumered[id];
        if (existingValue)
            dataArr.push({
                isNew: false,
                data: {
                    id: Number(existingValue.id),
                    userValue: id,
                    enabled: true,
                    order: index + 1,
                    listId
                }
            });
        else
            dataArr.push({
                isNew: true,
                data: {
                    enabled: true,
                    userValue: id,
                    value: String(id),
                    order: index + 1,
                    listId
                } as CreateListValuesVariables['data']
            });
    }

    for (const value of deletedUsersValue) {
        dataArr.push({
            isNew: false,
            data: {
                id: Number(value.id),
                userValue: Number(value.userValue.id),
                enabled: false,
                listId
            }
        });
    }

    return dataArr;
};

export const formSchema = yup
    .object({
        name: yup.string().trim().min(2, 'Name must be at least 2 characters').required(),
        description: yup.string().trim().min(2, 'Description must be at least 2 characters').required(),
        backofficeManaged: yup.boolean().typeError('backoffice managed is required').required(),
        listType: yup.string().trim().min(2, 'List Type is required').required()
    } as Record<keyof ListForm, any>)
    .required();

export const sortByCreatedAt =
    <T>(order: 'ASC' | 'DESC' = 'DESC') =>
    (a: T & { createdAt: string }, b: T & { createdAt: string }) => {
        const dateA = new Date(a.createdAt).valueOf();
        const dateB = new Date(b.createdAt).valueOf();

        return order === 'DESC' ? dateB - dateA : dateA - dateB;
    };

/**
 * Returns an object with list ids as keys and an array of values as value
 *
 * @param values {ListValue & ListId} array of values from FindListValues
 */
export const orderListValuesById = (values: ListValue[]) => {
    const obj: Record<number, ListValue[]> = {};

    for (const value of values) {
        const idx = Number(value.listId.id);
        if (obj[idx]) obj[idx].push(value);
        else obj[idx] = [value];
    }

    return obj;
};

/**
 * Return the intl id for each list data type
 *
 * @param listType {ListDataTypeEnum}
 */
export const getListTypeName = (listType: ListDataTypeEnum) => {
    switch (listType) {
        case 'User':
            return 'userList';
        case 'Value':
            return 'primitiveList';
        case 'Object':
            return 'objectList';
        case 'Other':
            return 'unknowList';
        default:
            return '';
    }
};

const getSchemaDataType = (dataType: IRecordField['dataType']) => {
    const sameNameDataTypes = ['boolean', 'string', 'number'] as string[];

    if (sameNameDataTypes.includes(dataType)) return dataType as (typeof sameNameDataTypes)[number];

    switch (dataType) {
        case 'checkbox':
            return 'boolean';
        case 'currency':
        case 'number':
            return 'number';
        default:
            return 'string';
    }
};

/**
 * Generate the yup schema for given properties
 *
 * @param properties {ObjectProperty[]}
 */
export const generateSchemaFromObjectProperties = (properties: ObjectProperty[]) => {
    const obj: Record<string, any> = {};

    for (const property of properties) {
        const datatype = getSchemaDataType(property.dataType as IRecordField['dataType']);
        const { isRequired } = property;

        if (datatype === 'number') {
            obj[`${property.id}-${property.name}`] = yup
                .mixed()
                .test('is-number-or-empty', 'Should be numeric', (value) => {
                    if (value === '') return true;
                    return yup.number().isValidSync(value);
                })
                .typeError(`${capitalize(property.name)} is required`);
        } else obj[`${property.id}-${property.name}`] = yup[datatype as 'string' | 'boolean']();

        if (isRequired)
            obj[`${property.id}-${property.name}`] = obj[`${property.id}-${property.name}`]
                .typeError(`${capitalize(property.name)} is required`)
                .required(`${capitalize(property.name)} is required`);
    }

    return yup.object(obj).required();
};

/**
 * Returns an object to be used in initial value of form
 *
 * @param properties {ObjectProperty[]}
 * @param values {ObjectValue[]}
 */
export const generateObjectInitialValue = (properties: ObjectProperty[], values?: ObjectValue[]) => {
    const obj: Record<string, any> = {};

    const currentValuesByPropertyId = generateCurrentValuesByPropertyId(values);

    // To generate the fieldName we used id and name of the property to avoid errors in fields with the same name
    for (const property of properties) {
        const currentValue = currentValuesByPropertyId ? currentValuesByPropertyId[+property.id]?.value : '';

        if (['checkbox', 'boolean'].includes(property.dataType)) obj[`${property.id}-${property.name}`] = currentValue || 'false';
        else obj[`${property.id}-${property.name}`] = currentValue || '';
    }

    return obj;
};

/**
 * Generate an object with propertyId as the key
 *
 * @param values {ObjectValue[]}
 */
export const generateCurrentValuesByPropertyId = (values?: ObjectValue[]): Record<number, ObjectValue> => {
    if (!values) return {};
    return values.reduce(
        (acc, el) => ({ ...acc, [+(el.objectProperty as Pick<ObjectProperties, 'id' | 'dataType' | 'name'>).id]: el }),
        {}
    );
};

/**
 * Returns an object only with the fields edited
 *
 * @param data {Object} form data
 * @param properiesDef {ObjectProperty} definition of the properties of the object
 */
export const generateSubmitData = (data: Record<string, any>, properiesDef: ObjectProperty[]) => {
    const cleanedObj: Record<string, any> = {};
    for (const fieldName in data) {
        if (Object.prototype.hasOwnProperty.call(data, fieldName)) {
            const element = data[fieldName];
            const [fieldId] = fieldName.split('-');
            // We split the fieldName to extract the id
            const fieldDef = properiesDef.find((el) => +el.id === +fieldId);

            if (element || fieldDef?.dataType === 'checkbox') {
                if (fieldDef?.dataType === 'date') cleanedObj[fieldId] = format(getDateWithoutTZ(element), 'yyyy-MM-dd');
                else if (fieldDef?.dataType === 'datetime') cleanedObj[fieldId] = format(new Date(element), 'yyyy-MM-dd hh:mm a');
                else if (fieldDef?.dataType === 'checkbox') cleanedObj[fieldId] = String(element);
                else cleanedObj[fieldId] = element;
            }
        }
    }
    return cleanedObj;
};

/**
 * Generate an string based on the values of the object
 *
 * @param object {DynamicObject}
 */
export const generateObjectString = (object: Pick<DynamicObject, 'id' | 'objectValues' | 'objectDefinition'> | null) => {
    if (!object) return '';
    const { objectValues } = object;

    const displayableProperties = [...objectValues]
        .sort((a, b) => (a.objectProperty?.order || 0) - (b.objectProperty?.order || 0))
        .filter((el) => el.objectProperty?.isDisplayable);

    if (!displayableProperties.length)
        return 'No display value setted, please contact an administrator to set display values on object configuration';

    let returnValue = displayableProperties
        .map((value) => {
            if (!value.objectProperty?.id) return value.value;
            const { dataType, name } = value.objectProperty;

            if (dataType === 'date') return dayjs(value.value).format('YYYY-MM-DD');
            if (dataType === 'datetime') return dayjs(value.value).format('YYYY-MM-DD hh:mm a');

            if (['boolean', 'checkbox'].includes(dataType)) return `${name}: ${JSON.parse(value.value) ? 'Yes' : 'No'}`;

            return value.value;
        })
        .join(', ');
    if (returnValue === '') returnValue = 'No display value filled, please fill at least one display value property';
    return returnValue;
};

/**
 * Data types allowed to show its values in a grid
 */
export const ENTER_ROW_DATATYPES_ALLOWED = ['Value', 'Object'] as Partial<ListDataTypeEnum>[];

/**
 * Data types allowed to have inline editing
 */
export const INLINE_EDITING_DATATYPES_ALLOWED = ['Value'] as Partial<ListDataTypeEnum>[];

export const dayJSFormatToGridDate = 'YYYY-MM-DD';
export const dayJSFormatToGridDateTime = 'YYYY-MM-DD HH:mm';
export const dayJSFormatRawDateFromExcel = 'MM/DD/YYYY';
export const dayJSFormatRawDateTimeFromExcel = 'MM/DD/YYYY  HH:mm';

export const validateImportedValue = (value: any, isRequired: boolean, fieldName: string, dataType: string) => {
    // Empty required field
    if (isRequired && !value) {
        return { error: true, message: `${fieldName} is required` };
    }
    // Empty non required field
    if (!isRequired && !value) {
        return { error: false, message: `` };
    }
    // Validate field datatype
    switch (dataType) {
        case 'number':
            return !isNaN(Number(value)) ? { error: false, message: `` } : { error: true, message: `${fieldName} must be a number` };
        case 'date':
            return dayjs(value, dayJSFormatToGridDate, true).isValid()
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} Invalid date format, format should be MM/DD/YYYY` };
        case 'datetime':
            return dayjs(value, dayJSFormatToGridDateTime, true).isValid()
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} Invalid datetime format, format should be MM/DD/YYYY HH:mm (24 hours)` };
        case 'checkbox':
            return ['Yes', 'No'].includes(value)
                ? { error: false, message: `` }
                : { error: true, message: `${fieldName} must be Yes or No` };
        default:
            return { error: false, message: `` };
    }
};

export const mapImportedValueToPreview = (value: any, dataType: string) => {
    if (!value) return value;
    switch (dataType) {
        case 'number':
            return +value;
        case 'date':
            return dayjs(value, dayJSFormatToGridDate).format('YYYY-MM-DD');
        case 'datetime':
            return dayjs(value, dayJSFormatToGridDateTime).utc().format();
        case 'checkbox':
            return value === 'Yes';
        default:
            return value;
    }
};

export const parseImportedValue = (value: any, dataType: string) => {
    if (!value) return value;
    switch (dataType) {
        case 'number':
            return +value;
        case 'date':
            return dayjs(value.substring(0, 10), dayJSFormatRawDateFromExcel).format(dayJSFormatToGridDate);
        case 'datetime':
            const dateSplitted = value.split(' ');
            const timeSplitted = dateSplitted[1].split(':');
            const parsedDateTime = dayjs(dateSplitted[0], dayJSFormatRawDateFromExcel).hour(+timeSplitted[0]).minute(+timeSplitted[1]);
            return parsedDateTime.format(dayJSFormatToGridDateTime);
        default:
            return value;
    }
};

export const mapObjectPropertiesWithExcelKeys = (objectProperties: ObjectProperty[], sheetData: any) =>
    objectProperties.map((prop) => {
        const propMapped: ObjectProperty & { excelKey?: string } = { ...prop };
        Object.keys(sheetData).forEach((key) => {
            if (prop.name.trim() === sheetData[key].replaceAll('*', '').trim()) {
                propMapped.excelKey = key;
            }
        });
        return propMapped;
    });

export const mapAndValidateImportedValues = (sheetData: any, objectPropertiesMapped: any) => {
    const newValues: any[] = [];
    const validation: { error: boolean; message: string }[] = [];
    sheetData.forEach((sData: any, index: number) => {
        if (index > 0) {
            const newRow = [...objectPropertiesMapped].map((prop) => {
                const propValidation = validateImportedValue(
                    ['date', 'datetime'].includes(prop.dataType)
                        ? parseImportedValue(sData[prop.excelKey ?? ''], prop.dataType)
                        : sData[prop.excelKey ?? ''],
                    prop.isRequired,
                    prop.name,
                    prop.dataType
                );
                if (propValidation.error) throw new Error(propValidation.message);
                validation.push(propValidation);
                return {
                    ...prop,
                    value: parseImportedValue(sData[prop.excelKey ?? ''], prop.dataType) ?? ''
                };
            });
            newValues.push(newRow);
        }
    });
    return { newValues, validation };
};
