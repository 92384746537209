import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { QUERY_GET_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import { FindObjectProperty, FindObjectPropertyVariables, ObjectDefinition } from 'views/backoffice/CustomObjects/types';
import { ListValue } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateObjectInitialValue, generateSchemaFromObjectProperties, generateSubmitData } from '../utils';
import { RenderInput } from 'ui-component/RecordView/components';
import { IRecordField } from 'ui-component/records/types';
import { capitalize } from 'utils/stringHelpers';

export type ObjectsFormDialogProps = {
    open: boolean;
    onSubmit: (data: Record<string, any>) => Promise<void>;
    onClose: () => void;
    listValueToEdit: ListValue | null;
    objectDefinition?: ObjectDefinition | null;
};

export const ObjectsFormDialog = ({ open, onSubmit, onClose, listValueToEdit, objectDefinition }: ObjectsFormDialogProps) => {
    const isEdit = !!listValueToEdit;
    const [isLoading, setIsLoading] = useState(false);

    const [getObjectProperties, { loading: loadingObjectProperties, data: objectPropertiesData }] = useLazyQuery<
        FindObjectProperty,
        FindObjectPropertyVariables
    >(QUERY_GET_OBJECT_PROPERTIES);

    const objectProperties = useMemo(
        () =>
            objectPropertiesData?.findObjectProperty ? [...objectPropertiesData.findObjectProperty].sort((a, b) => a.order - b.order) : [],
        [objectPropertiesData?.findObjectProperty]
    );

    const formSchema = generateSchemaFromObjectProperties(objectProperties);
    // console.log('###formSchema', formSchema);

    const {
        control,
        reset,
        handleSubmit,
        resetField,
        setValue,
        formState: { isValid, errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema)
    });

    const handleClickSave = async (data: Record<string, any>) => {
        setIsLoading(true);
        await onSubmit(generateSubmitData(data, objectProperties));
        setIsLoading(false);
    };

    const initializeForm = useCallback(async () => {
        if (open && objectDefinition) {
            // TODO: fix this variables definition, this way it's working so the definition could be wrong
            // @ts-ignore
            await getObjectProperties({ variables: { data: { objectDefinitionIds: [+objectDefinition.id] } } });
            const initialValue = generateObjectInitialValue(objectProperties, listValueToEdit?.dynamicObjectValue?.objectValues);
            reset(initialValue);
        }
    }, [open, objectDefinition, listValueToEdit, getObjectProperties, objectProperties, reset]);

    useEffect(() => {
        initializeForm();
    }, [initializeForm]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ pt: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={11}>
                        <Typography variant="h5" fontSize="24px">
                            {isEdit ? 'Edit Value' : 'Create Value'}
                        </Typography>
                        <Typography variant="h6" fontSize="12px" sx={{ color: '#54595E' }}>
                            View all object properties here.
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton onClick={onClose}>
                            <HighlightOffOutlined fontSize="medium" color="secondary" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {objectProperties.map((property) => (
                        <Grid item container alignItems="center" xs={12} key={property.id}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" fontSize="12px" sx={{ color: '#858585' }}>
                                    {capitalize(property.name)}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <RenderInput
                                    control={control}
                                    errors={errors}
                                    name={`${property.id}-${property.name}`}
                                    hideTitle
                                    backgroundAlways
                                    TextFieldComponentProps={{
                                        size: 'small',
                                        variant: 'filled',
                                        InputProps: {
                                            hiddenLabel: true,
                                            disableUnderline: true,
                                            sx: {
                                                borderRadius: '8px',
                                                '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                                    backgroundColor: (theme) => `${theme.palette.primary[300]} !important`,
                                                    color: (theme) => `${theme.palette.secondary.main} !important`,
                                                    '&::placeholder': { color: 'transparent !important' },
                                                    '& svg': {
                                                        color: (theme) => `${theme.palette.secondary.main} !important`,
                                                        opacity: 0.5
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    setValue={setValue}
                                    resetField={resetField}
                                    fieldData={property as unknown as IRecordField}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    disabled={isLoading}
                    variant="outlined"
                    color="secondary"
                    sx={{ p: '12px !important', lineHeight: 1, borderRadius: '8px' }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    disabled={!isValid || loadingObjectProperties}
                    loading={isLoading}
                    color="secondary"
                    sx={{ p: '12px !important', lineHeight: 1, borderRadius: '8px' }}
                    onClick={handleSubmit(handleClickSave)}
                    loadingIndicator={isEdit ? 'Saving' : 'Creating'}
                    variant="contained"
                >
                    {isEdit ? 'Save' : 'Create'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
